import type { RouteRecordRaw } from 'vue-router';
import { featureFlags } from '@/lib/flagsmith';
import { authGuard } from '@/router/guards/authGuard';
import { DataPointRequestStatusEnum, UserRole } from '@/__generated__/types';
import TmLibraryGuidancesView from '@/components/pages/PgLibrary/TmLibraryGuidancesView.vue';
import TmLibrarySourcesView from '@/components/pages/PgLibrary/TmLibrarySourcesView.vue';
import TmLibraryUploadsView from '@/components/pages/PgLibrary/TmLibraryUploadsView.vue';
import PgAssessment from '../components/pages/Modules/Taxonomy/Assessment/PgAssessment.vue';
import OgBusinessActivities
  from '../components/pages/Modules/Taxonomy/Assessment/BusinessActivities/OgBusinessActivities.vue';
import TmBusinessActivitiesSectorsActivities
  from '../components/pages/Modules/Taxonomy/Assessment/BusinessActivities/TmSectorsActivities.vue';
import TmBusinessActivitiesSectorsTurnover
  from '../components/pages/Modules/Taxonomy/Assessment/BusinessActivities/TmTurnover.vue';
import TmBusinessActivitiesSectorsCapex
  from '../components/pages/Modules/Taxonomy/Assessment/BusinessActivities/TmCapex.vue';
import TmBusinessActivitiesSectorsOpex
  from '../components/pages/Modules/Taxonomy/Assessment/BusinessActivities/TmOpex.vue';
import PgAssessmentCreate from '../components/pages/Modules/Taxonomy/Assessment/PgAssessmentCreate.vue';
import OgSubstantialContribution
  from '../components/pages/Modules/Taxonomy/Assessment/SubstantialContribution/OgSubstantialContribution.vue';
import TmSubstantialContributionObjectives
  from '../components/pages/Modules/Taxonomy/Assessment/SubstantialContribution/TmObjectives.vue';
import TmSubstantialContributionActivityFit
  from '../components/pages/Modules/Taxonomy/Assessment/SubstantialContribution/TmActivityFit.vue';
import OgDoNoSignificantHarm
  from '../components/pages/Modules/Taxonomy/Assessment/DoNoSignificantHarm/OgDoNoSignificantHarm.vue';
import TmDoNoSignificantHarmChecklist
  from '../components/pages/Modules/Taxonomy/Assessment/DoNoSignificantHarm/TmChecklist/TmChecklist.vue';
import OgMinimumSafeguards
  from '../components/pages/Modules/Taxonomy/Assessment/MinimumSafeguards/OgMinimumSafeguards.vue';
import TmMinimumSafeguardsChecklist
  from '../components/pages/Modules/Taxonomy/Assessment/MinimumSafeguards/TmChecklist.vue';
import OgDocuments from '../components/pages/Modules/Taxonomy/Assessment/Documents/OgDocuments.vue';
import TmDocumentsUpload from '../components/pages/Modules/Taxonomy/Assessment/Documents/TmUpload.vue';
import PgAuditTrail from '../components/pages/PgRepository/AuditTrail.vue';
import TmOverviewView from '../components/pages/PgHome/TmOverviewView/TmOverviewView.vue';
import PgGoalsOverview from '../components/pages/PgGoals/PgGoalsOverview/PgGoalsOverview.vue';
import PgKPI from '../components/pages/PgGoals/PgKPI/PgKPI.vue';
import TmChartView from '../components/pages/PgStatistics/TmChartView/TmChartView.vue';
import PgResults from '../components/pages/PgStatistics/PgResults/PgResults.vue';
import PgCarbonFootprintView from '../components/pages/PgStatistics/PgResults/PgCarbonFootprintView/PgCarbonFootprintView.vue';
import PgStandardView from '../components/pages/PgStatistics/PgResults/PgStandardView/PgStandardView.vue';
import PgQualitativeView from '../components/pages/PgStatistics/PgResults/PgQualitativeView/PgQualitativeView.vue';
import TmInsightsView from '../components/pages/PgStatistics/TmInsightsView/TmInsightsView.vue';
import PgLoginCallback from '../components/pages/PgLoginCallback/PgLoginCallback.vue';
import PgLogout from '../components/pages/PgLogout.vue';
import PgLoginCallbackCypress from '../components/pages/PgLoginCallback/PgLoginCallbackCypress.vue';
import PgLogoutCallback from '../components/pages/PgLogoutCallback.vue';
import PgEmailVerified from '../components/pages/PgEmailVerified.vue';
import PgAnalyticsDashboard from '../components/pages/PgAnalytics/PgAnalyticsDashboard.vue';
import PgApproval from '../components/pages/PgApproval/PgApproval.vue';
import PgProjects from '../components/pages/PgProjects/PgProjects.vue';
import PgProjectsList from '../components/pages/PgProjects/PgProjectsList.vue';
import PgProject from '../components/pages/PgProjects/PgProject/PgProject.vue';
import TmDataEntry from '../components/pages/PgProjects/PgProject/PgProjectDataEntry.vue';
import TmArchive from '../components/pages/PgProjects/PgProject/PgProjectArchive.vue';
import PgDataEntryUpload from '../components/pages/PgDataEntryUpload.vue';
import PgDataEntryUploadInternal from '../components/pages/PgDataEntryUploadInternal.vue';
import PgStatistics from '../components/pages/PgStatistics/PgStatistics.vue';
import PgGetStarted from '../components/pages/PgGetStarted/PgGetStarted.vue';
import PgHome from '../components/pages/PgHome/PgHome.vue';
import TmSettingsProfile from '../components/pages/PgSettings/TmSettingsProfile.vue';
import TmSettingsTeam from '../components/pages/PgSettings/TmSettingsTeam.vue';
import TmSettingsEntities from '../components/pages/PgAdmin/Settings/TmSettingsEntities.vue';
import PgRepository from '../components/pages/PgRepository.vue';
import PgLoginLayout from '../components/pages/PgLogin/Layout.vue';
import PgLoginStart from '../components/pages/PgLogin/PgStart.vue';
import PgLoginChoose from '../components/pages/PgLogin/PgChoose.vue';
import PgLoginPassword from '../components/pages/PgLogin/PgPassword.vue';
import PgLoginSignup from '../components/pages/PgLogin/PgSignup.vue';
import PgLoginPasswordlessCode from '../components/pages/PgLogin/PgPasswordlessCode.vue';
import PgOnboarding from '../components/pages/PgOnboarding/PgOnboarding.vue';
import PgAdminLayout from '../components/pages/PgAdmin/Layout.vue';
import PgAdminDashboardOverview from '../components/pages/PgAdmin/Home/Dashboard.vue';
import PgAdminSettings from '../components/pages/PgAdmin/Settings/Settings.vue';
import PgAdminReportsOverview from '../components/pages/PgAdmin/Home/TmReports/TmReports.vue';
import PgExternalLayout from '../components/pages/PgExternal/Layout.vue';
import PgExternalLogin from '../components/pages/PgExternal/Login.vue';
import PgExternalDashboard from '../components/pages/PgExternal/Dashboard.vue';
import PgTaxonomy from '../components/pages/Modules/Taxonomy/PgTaxonomy.vue';
import PgCalculators from '../components/pages/Modules/Calculators/PgCalculators.vue';
import PgTaxonomyOverview from '../components/pages/Modules/Taxonomy/PgOverview/PgOverview.vue';
import PgNotificationSettings from '../components/pages/PgAdmin/Settings/PgNotificationSettings.vue';
import PgTaxonomyProjects from '../components/pages/Modules/Taxonomy/PgProjects/PgProjects.vue';
import PgTaxonomyResults from '../components/pages/Modules/Taxonomy/PgResults/PgResults.vue';
import PgExternalDataEntryLayout from '../components/pages/PgExternalDataEntry/Layout.vue';
import PgExternalDataEntryQuestionnaire from '../components/pages/PgExternalDataEntry/Questionnaire.vue';
import PgExternalDataEntryLogin from '../components/pages/PgExternalDataEntry/Login.vue';
import PgLibrary from '../components/pages/PgLibrary/PgLibrary.vue';
import PgCustomQuestionnaire from '../components/pages/PgProjects/PgProject/PgCustomQuestionnaire.vue';
import PgCustomQuestionnaireUpload from '../components/pages/PgProjects/PgProject/PgCustomQuestionnaireUpload/PgCustomQuestionnaireUpload.vue';
// import PgCustomQuestionnaireCheckMapping from '../components/pages/PgProjects/PgProject/PgCustomQuestionnaireCheckMapping/PgCustomQuestionnaireCheckMapping.vue';
import PgCustomQuestionnaireEditQuestionnaire from '../components/pages/PgProjects/PgProject/PgCustomQuestionnaireEditQuestionnaire/PgCustomQuestionnaireEditQuestionnaire.vue';
import PgCustomQuestionnaireShare from '../components/pages/PgProjects/PgProject/PgCustomQuestionnaireShare/PgCustomQuestionnaireShare.vue';
import PgCarbonFootprintCalculator from '../components/pages/Modules/Calculators/CarbonFootprintCalculator.vue';
import PgCarbonFootprintSources from '../components/pages/Modules/Calculators/CarbonFootprintSources.vue';
import PgDataRoom from '../components/pages/Modules/Taxonomy/PgDataRoom/PgDataRoom.vue';
import PgExternalTaxonomyAssessmentLogin from '../components/pages/Modules/Taxonomy/External/PgExternalTaxonomyAssessmentLogin.vue';
import PgExternalTaxonomyAssessmentAnswer from '../components/pages/Modules/Taxonomy/External/PgExternalTaxonomyAssessmentAnswer/PgExternalTaxonomyAssessmentAnswer.vue';
import OgDoubleMateriality from '../components/pages/Modules/DoubleMateriality/OgDoubleMateriality.vue';
import PgDoubleMaterialityStart from '../components/pages/Modules/DoubleMateriality/PgStart/PgStart.vue';
import PgDoubleMaterialityStartGuidance from '../components/pages/Modules/DoubleMateriality/PgStart/PgGuidance/PgGuidance.vue';
import PgDoubleMaterialityStartTeam from '../components/pages/Modules/DoubleMateriality/PgStart/PgTeam/PgTeam.vue';
import PgDoubleMaterialityStartScalesAndCoreData from '../components/pages/Modules/DoubleMateriality/PgStart/PgScalesAndCoreData/PgScalesAndCoreData.vue';
import PgDoubleMaterialityTopics from '../components/pages/Modules/DoubleMateriality/PgTopics/PgTopics.vue';
import PgDoubleMaterialityTopicsSelection from '../components/pages/Modules/DoubleMateriality/PgTopics/PgSelection/PgSelection.vue';
import PgDoubleMaterialityIROs from '../components/pages/Modules/DoubleMateriality/PgIROs/PgIROs.vue';
import PgDoubleMaterialityIROsImpacts from '../components/pages/Modules/DoubleMateriality/PgIROs/PgImpacts/PgImpacts.vue';
import PgDoubleMaterialityIROsRisksAndOpportunities from '../components/pages/Modules/DoubleMateriality/PgIROs/PgRisksAndOpportunities/PgRisksAndOpportunities.vue';
import PgDoubleMaterialityIROsScore from '../components/pages/Modules/DoubleMateriality/PgIROs/PgScore/PgScore.vue';
import PgDoubleMaterialityIROsScoreActualNegative from '../components/pages/Modules/DoubleMateriality/PgIROs/PgScore/PgActualNegative/PgActualNegative.vue';
import PgDoubleMaterialityIROsScorePotentialNegative from '../components/pages/Modules/DoubleMateriality/PgIROs/PgScore/PgPotentialNegative/PgPotentialNegative.vue';
import PgDoubleMaterialityIROsScoreActualPositive from '../components/pages/Modules/DoubleMateriality/PgIROs/PgScore/PgActualPositive/PgActualPositive.vue';
import PgDoubleMaterialityIROsScorePotentialPositive from '../components/pages/Modules/DoubleMateriality/PgIROs/PgScore/PgPotentialPositive/PgPotentialPositive.vue';
import PgDoubleMaterialityIROsScoreRisksAndOpportunities from '../components/pages/Modules/DoubleMateriality/PgIROs/PgScore/PgRisksAndOpportunities/PgRisksAndOpportunities.vue';
import PgDoubleMaterialityStakeholders from '../components/pages/Modules/DoubleMateriality/PgStakeholders/PgStakeholders.vue';
import PgDoubleMaterialityStakeholdersStakeholders from '../components/pages/Modules/DoubleMateriality/PgStakeholders/PgStakeholders/PgStakeholders.vue';
import PgDoubleMaterialityStakeholdersSurveys from '../components/pages/Modules/DoubleMateriality/PgStakeholders/PgSurveys/PgSurveys.vue';
import PgDoubleMaterialityStakeholdersResults from '../components/pages/Modules/DoubleMateriality/PgStakeholders/PgResults/PgResults.vue';
import PgDoubleMaterialityResults from '../components/pages/Modules/DoubleMateriality/PgResults/PgResults.vue';
import PgDoubleMaterialityResultsList from '../components/pages/Modules/DoubleMateriality/PgResults/PgList/PgList.vue';
import PgDoubleMaterialityResultsMatrix from '../components/pages/Modules/DoubleMateriality/PgResults/PgMatrix/PgMatrix.vue';
import PgDoubleMaterialityResultsRecommendedESRS from '../components/pages/Modules/DoubleMateriality/PgResults/PgRecommendedESRS/PgRecommendedESRS.vue';
import PgExternalDoubleMaterialitySurveyLogin from '../components/pages/Modules/DoubleMateriality/External/PgExternalDoubleMaterialitySurveyLogin.vue';
import PgExternalDoubleMaterialitySurveyAnswer from '../components/pages/Modules/DoubleMateriality/External/PgExternalDoubleMaterialitySurveyAnswer/PgExternalDoubleMaterialitySurveyAnswer.vue';

export const routes: RouteRecordRaw[] = [
  { path: '/loginCallbackCypress', component: PgLoginCallbackCypress },
  { path: '/logoutCallback', component: PgLogoutCallback },
  { path: '/emailVerified', component: PgEmailVerified },

  { path: '/signup', redirect: '/login/signup' },

  {
    path: '/loginCallback',
    component: PgLoginLayout,
    name: 'loginCallback',
    children: [{ path: '', name: 'loginCallback', component: PgLoginCallback }],
  },

  {
    name: 'login',
    path: '/login',
    redirect: { name: 'loginStart' },
    component: PgLoginLayout,
    children: [
      {
        name: 'loginStart',
        path: 'start',
        component: PgLoginStart,
        props: true,
        meta: { isShown: () => false },
      },
      {
        name: 'loginChoose',
        path: 'choose',
        component: PgLoginChoose,
        props: true,
        meta: { isShown: () => false },
      },
      {
        name: 'loginPasswordlessCode',
        path: 'passwordlesscode',
        component: PgLoginPasswordlessCode,
        props: true,
        meta: { isShown: () => false },
      },
      {
        name: 'loginPassword',
        path: 'password',
        component: PgLoginPassword,
        props: true,
        meta: { isShown: () => false },
      },
      {
        name: 'signup',
        path: 'signup',
        component: PgLoginSignup,
        props: true,
        meta: { isShown: () => false },
      },
    ],
  },

  { name: 'logout', path: '/logout', component: PgLogout },

  { path: '/', redirect: '/home' },
  {
    name: 'home',
    path: '/home',
    redirect: '/home/overview',
    components: { default: PgHome },
    beforeEnter: authGuard,
    meta: {
      // don't re-add the role requirement, it will lead to a loop when the token expired
      // role: [UserRole.ADMIN, UserRole.MANAGER, UserRole.CONTRIBUTOR],
      label: 'Home',
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'homeOverview',
        path: 'overview',
        components: { default: PgHome, subview: TmOverviewView },
        meta: { label: 'Overview', isShown: () => false },
      },
    ],
  },
  {
    name: 'statistics',
    path: '/statistics',
    redirect: '/statistics/results',
    component: PgStatistics,
    beforeEnter: authGuard,
    meta: {
      label: 'Statistics',
      roleFrom: [UserRole.MANAGER, UserRole.AUDITOR],
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'homeDashboard',
        path: 'dashboard',
        components: { default: PgStatistics, subview: TmChartView },
        beforeEnter: authGuard,
        meta: { label: 'Dashboard', roleFrom: UserRole.MANAGER },
      },
      {
        name: 'homeResults',
        path: 'results',
        components: { default: PgStatistics, subview: PgResults },
        beforeEnter: authGuard,
        meta: {
          label: 'Results',
          roleFrom: [UserRole.MANAGER, UserRole.AUDITOR],
        },
        redirect: {
          name: 'resultsStandardView',
        },
        children: [
          {
            name: 'resultsCarbonFootprintView',
            path: 'carbon-footprint',
            component: PgCarbonFootprintView,
            beforeEnter: () => {
              if (!featureFlags.emissions) {
                return {
                  name: 'resultsStandardView',
                };
              }
            },
            props: true,
            meta: {
              isShown: () => false,
            },
          },
          {
            name: 'resultsStandardView',
            path: 'all-data',
            component: PgStandardView,
            props: true,
            meta: {
              isShown: () => false,
            },
          },
        ],
      },
      {
        name: 'homeInsights',
        path: 'insights',
        components: { default: PgStatistics, subview: TmInsightsView },
        beforeEnter: authGuard,
        meta: {
          roleFrom: UserRole.CONTRIBUTOR,
          label: 'Insights',
          isShown: () => featureFlags.insights,
        },
      },
    ],
  },

  {
    name: 'getStarted',
    path: '/get-started/',
    component: PgGetStarted,
    beforeEnter: authGuard,
    meta: {
      label: 'Get Started',
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'start',
        path: 'start',
        component: { default: PgGetStarted },
        meta: { isShown: () => false },
      },
    ],
  },

  {
    name: 'projects',
    path: '/projects',
    component: PgProjects,
    beforeEnter: authGuard,
    redirect: '/projects',
    meta: {
      roleFrom: UserRole.CONTRIBUTOR,
      showInCommandPalette: true,
    },
    children: [
      {
        path: '',
        name: 'projects',
        component: PgProjectsList,
      },
      {
        name: 'projectsCustomQuestionnaire',
        path: 'custom-questionnaire/:customQuestionnaireId',
        redirect: { name: 'projectsCustomQuestionnaireUpload' },
        component: PgCustomQuestionnaire,
        props: true,
        beforeEnter: [
          (to) => {
            if (!featureFlags.customQuestionnaire) {
              return {
                name: 'projects',
                params: to.params,
              };
            }
          },
          authGuard,
        ],
        meta: { isShown: () => false },
        children: [
          {
            name: 'projectsCustomQuestionnaireUpload',
            path: 'upload',
            component: PgCustomQuestionnaireUpload,
            props: true,
            meta: { isShown: () => false },
          },
          // Check mapping page is temporarily disabled.
          // {
          //   name: 'projectsCustomQuestionnaireCheckMapping',
          //   path: 'check-mapping',
          //   component: PgCustomQuestionnaireCheckMapping,
          //   props: true,
          //   meta: { isShown: () => false },
          // },
          {
            name: 'projectsCustomQuestionnaireEditQuestionnaire',
            path: 'edit-questionnaire',
            component: PgCustomQuestionnaireEditQuestionnaire,
            props: true,
            meta: { isShown: () => false },
          },
          {
            name: 'projectsCustomQuestionnaireShare',
            path: 'share',
            component: PgCustomQuestionnaireShare,
            props: true,
            meta: { isShown: () => false },
          },
        ],
      },
      {
        path: ':project',
        component: PgProject,
        props: true,
        meta: { isShown: () => false },
        redirect: { name: 'projectsProject' },
        children: [
          {
            path: '',
            name: 'projectsProject',
            component: PgProjectsList,
            props: true,
          },
          {
            name: 'projectsProjectDataEntry',
            path: 'data-entry',
            component: TmDataEntry,
            props: true,
            meta: { isShown: () => false },
          },
          {
            name: 'projectsProjectArchive',
            path: 'archive',
            component: TmArchive,
            props: true,
            beforeEnter: authGuard,
            meta: { roleFrom: UserRole.ADMIN, isShown: () => false },
          },
        ],
      },
    ],
  },

  {
    name: 'approval',
    path: '/approval',
    component: PgApproval,
    beforeEnter: authGuard,
    meta: {
      label: 'Approval',
      roleFrom: UserRole.MANAGER,
      showInCommandPalette: true,
    },
    redirect: '/approval/pending',
    children: [
      {
        name: 'approvalPending',
        path: 'pending/:dataPointRequest?',
        components: { default: PgApproval },
        meta: {
          label: 'Pending',
          approvalStatus: DataPointRequestStatusEnum.PENDING,
        },
      },
      {
        name: 'approvalRejected',
        path: 'rejected/:dataPointRequest?',
        components: { default: PgApproval },
        meta: {
          label: 'Rejected',
          approvalStatus: DataPointRequestStatusEnum.REJECTED,
        },
      },
      {
        name: 'approvalApproved',
        path: 'approved/:dataPointRequest?',
        components: { default: PgApproval },
        meta: {
          label: 'Approved',
          approvalStatus: DataPointRequestStatusEnum.ACCEPTED,
        },
      },
    ],
  },

  {
    name: 'repository',
    path: '/repository',
    redirect: { name: 'repositoryEnvironment' },
    component: PgRepository,
    beforeEnter: authGuard,
    meta: {
      roleFrom: UserRole.MANAGER,
      label: 'File Repository',
      showInCommandPalette: true,
    },
    children: [],
  },

  {
    name: 'dataEntryUpload',
    path: '/data-entry-upload',
    component: PgDataEntryUpload,
    beforeEnter: authGuard,
    redirect: '/data-entry-upload/upload',
    meta: {
      label: 'Data Upload',
      roleFrom: UserRole.CONTRIBUTOR,
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'upload',
        path: 'upload',
        components: { default: PgDataEntryUpload },
        meta: { label: 'Upload', isShown: () => false },
      },
    ],
  },

  {
    name: 'dataEntryUploadInternal',
    path: '/data-entry-upload-internal',
    component: PgDataEntryUploadInternal,
    beforeEnter: authGuard,
    redirect: '/data-entry-upload-internal/upload',
    meta: {
      label: 'Data Upload',
      isShown: () => false,
      showInCommandPalette: false,
    },
    children: [
      {
        name: 'upload-internal',
        path: 'upload',
        components: { default: PgDataEntryUploadInternal },
        meta: { label: 'Upload' },
      },
    ],
  },

  {
    name: 'library',
    path: '/library',
    component: PgLibrary,
    beforeEnter: authGuard,
    redirect: '/library/guidances',
    meta: {
      label: 'Evidence library',
      roleFrom: UserRole.CONTRIBUTOR,
      isShown: () => false,
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'libraryGuidances',
        path: 'guidances',
        component: TmLibraryGuidancesView,
        meta: { label: 'Guidance' },
      },
      {
        name: 'libraryUploads',
        path: 'uploads',
        component: TmLibraryUploadsView,
        meta: { label: 'User uploads' },
      },
      {
        name: 'librarySources',
        path: 'sources',
        component: TmLibrarySourcesView,
        meta: { label: 'Source documents' },
      },
    ],
  },

  {
    name: 'onboarding',
    path: '/onboarding',
    component: PgOnboarding,
    beforeEnter: authGuard,
  },

  {
    name: 'settings',
    path: '/settings',
    beforeEnter: authGuard,
    redirect: { name: 'settingsProfile' },
    meta: {
      label: 'Settings',
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'settingsProfile',
        path: 'profile',
        component: TmSettingsProfile,
        beforeEnter: authGuard,
        meta: { label: 'Profile' },
      },
      {
        name: 'settingsTeam',
        path: 'team',
        component: TmSettingsTeam,
        beforeEnter: authGuard,
        meta: { roleFrom: UserRole.MANAGER, label: 'Team' },
      },
    ],
  },

  {
    name: 'analytics',
    path: '/analytics',
    redirect: '/analytics/dashboard',
    component: PgAnalyticsDashboard,
    beforeEnter: authGuard,
    meta: {
      label: 'Analytics',
      roleFrom: UserRole.ADMIN,
      showInCommandPalette: false,
    },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        components: { default: PgAnalyticsDashboard },
        meta: { label: 'Details' },
      },
    ],
  },

  {
    name: 'adminHome',
    path: '/admin',
    redirect: '/admin/home/dashboard',
    component: PgAdminLayout,
    beforeEnter: authGuard,
    meta: {
      label: 'Home',
      roleFrom: [UserRole.ADMIN, UserRole.AUDITOR],
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'adminHomeDashboard',
        path: 'home/dashboard',
        component: PgAdminDashboardOverview,
        meta: { isShown: () => false },
      },
    ],
  },
  {
    name: 'adminStatistics',
    path: '/admin',
    redirect: '/admin/statistics/overview',
    component: PgAdminLayout,
    beforeEnter: authGuard,
    meta: {
      label: 'Home',
      roleFrom: [UserRole.ADMIN, UserRole.AUDITOR],
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'adminHomeOverview',
        path: 'statistics/overview',
        component: TmChartView,
        meta: { label: 'Dashboard' },
      },
      {
        name: 'adminHomeResults',
        path: 'statistics/results',
        component: PgResults,
        meta: { label: 'Results' },
        redirect: {
          name: 'adminResultsStandardView',
        },
        children: [
          {
            name: 'adminResultsCarbonFootprintView',
            path: 'carbon-footprint',
            component: PgCarbonFootprintView,
            beforeEnter: () => {
              if (!featureFlags.emissions) {
                return {
                  name: 'adminResultsStandardView',
                };
              }
            },
            props: true,
            meta: {
              isShown: () => false,
            },
          },
          {
            name: 'adminResultsStandardView',
            path: 'all-data',
            component: PgStandardView,
            props: true,
            meta: {
              isShown: () => false,
            },
          },
          {
            name: 'adminResultsQualitativeView',
            path: 'qualitative',
            component: PgQualitativeView,
            props: true,
            meta: {
              isShown: () => false,
            },
          },
        ],
      },
      {
        name: 'adminHomeInsights',
        path: 'statistics/insights',
        component: TmInsightsView,
        meta: { label: 'Insights' },
      },
    ],
  },

  {
    name: 'adminGoals',
    path: '/admin/goals',
    component: PgAdminLayout,
    beforeEnter: authGuard,
    redirect: '/admin/goals/overview',
    meta: {
      label: 'Goals',
      roleFrom: UserRole.ADMIN,
      isShown: () => false,
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'goalsOverview',
        path: 'overview',
        components: { default: PgGoalsOverview },
        meta: { label: 'Overview', isShown: () => false },
      },
      {
        name: 'goalsKpi',
        path: ':goal/:target/:kpi',
        components: { default: PgKPI },
        meta: { label: 'KPI', isShown: () => false },
      },
    ],
  },

  {
    name: 'adminReports',
    path: '/admin/reports',
    component: PgAdminLayout,
    beforeEnter: authGuard,
    redirect: '/admin/reports/overview',
    meta: {
      label: 'Goals',
      roleFrom: UserRole.ADMIN,
      isShown: () => false,
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'adminReports',
        path: 'overview',
        component: PgAdminReportsOverview,
        meta: { isShown: () => false },
      },
    ],
  },

  {
    name: 'adminAudit',
    path: '/admin/audit',
    component: PgAdminLayout,
    beforeEnter: authGuard,
    redirect: '/admin/audit/repository',
    meta: {
      label: 'Audit',
      roleFrom: [UserRole.ADMIN, UserRole.AUDITOR],
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'adminAuditRepository',
        path: 'repository',
        components: { default: PgRepository },
        meta: {
          label: 'File repository',
        },
      },

      {
        name: 'adminAuditTrail',
        path: 'trail',
        components: { default: PgAuditTrail },
        meta: {
          label: 'Audit trail',
        },
      },
    ],
  },

  {
    name: 'adminSettings',
    path: '/admin/settings',
    component: PgAdminLayout,
    beforeEnter: authGuard,
    redirect: '/admin/settings/company',
    meta: {
      label: 'Settings',
      roleFrom: UserRole.ADMIN,
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'adminCompanySettings',
        path: 'company',
        component: PgAdminSettings,
        meta: {
          label: 'Company',
        },
      },
      {
        name: 'adminTeamSettings',
        path: 'team',
        component: TmSettingsTeam,
        meta: {
          label: 'Team',
        },
      },
      {
        name: 'adminEntitySettings',
        path: 'workspaces',
        component: TmSettingsEntities,
        meta: {
          label: 'Workspaces',
        },
      },
      {
        name: 'notificationSettings',
        path: 'notifications',
        component: PgNotificationSettings,
        meta: {
          label: 'Notifications',
        },
      },
    ],
  },
  {
    name: 'externalDataEntry',
    path: '/external-data-entry',
    component: PgExternalDataEntryLayout,
    redirect: 'external-data-entry/login/:dataEntryShareToken',
    children: [
      {
        name: 'externalDataEntryQuestionnaire',
        path: 'questionnaire/:dataEntryShareToken',
        component: PgExternalDataEntryQuestionnaire,
        meta: { isShown: () => false },
      },
    ],
  },
  {
    name: 'externalDataEntryLogin',
    path: '/external-data-entry/login/:dataEntryShareToken',
    component: PgExternalDataEntryLogin,
  },

  {
    name: 'externalDashboard',
    path: '/external',
    component: PgExternalLayout,
    redirect: '/external/login',
    children: [
      {
        name: 'externalDashboard',
        path: 'dashboard',
        component: PgExternalDashboard,
        meta: { isShown: () => false },
      },
    ],
  },
  {
    name: 'externalLogin',
    path: '/external/login',
    component: PgExternalLogin,
  },

  {
    name: 'modules',
    path: '/modules',
    // component: PgModules,
    beforeEnter: authGuard,
    redirect: '/modules/taxonomy',
    meta: {
      label: 'Modules',
      roleFrom: UserRole.CONTRIBUTOR,
      showInCommandPalette: true,
    },
  },

  {
    name: 'calculators',
    path: '/modules/calculators',
    component: PgCalculators,
    redirect: '/modules/calculators/carbonFootprintCalculator',
    beforeEnter: authGuard,
    meta: {
      label: 'Calculators',
      roleFrom: UserRole.CONTRIBUTOR,
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'carbonFootprintCalculator',
        path: 'carbonFootprintCalculator',
        component: PgCarbonFootprintCalculator,
        meta: { isShown: () => false },
      },
      {
        name: 'carbonFootprintSources',
        path: 'carbonFootprintSources',
        component: PgCarbonFootprintSources,
        meta: { isShown: () => false },
      },
    ],
  },

  {
    name: 'taxonomy',
    path: '/modules/taxonomy',
    component: PgTaxonomy,
    beforeEnter: authGuard,
    redirect: '/modules/taxonomy/overview',
    meta: {
      label: 'Taxonomy',
      roleFrom: UserRole.CONTRIBUTOR,
      showInCommandPalette: true,
    },
    children: [
      {
        name: 'taxonomyOverview',
        path: 'overview',
        component: PgTaxonomyOverview,
        meta: { isShown: () => false },
      },
      {
        name: 'taxonomyProjects',
        path: 'projects',
        component: PgTaxonomyProjects,
        meta: { isShown: () => false },
      },
      {
        name: 'taxonomyResults',
        path: 'results/:projectId?',
        component: PgTaxonomyResults,
        meta: { isShown: () => false },
      },
      {
        name: 'taxonomyDataRoom',
        path: 'dataRoom',
        component: PgDataRoom,
        meta: { isShown: () => false },
      },
    ],
  },

  {
    name: 'taxonomyAssessmentCreate',
    path: '/modules/taxonomy/projects/:projectId/assessment',
    component: PgAssessmentCreate,
    beforeEnter: authGuard,
    meta: { label: 'Assessment', roleFrom: UserRole.CONTRIBUTOR },
  },

  {
    name: 'taxonomyAssessment',
    path: '/modules/taxonomy/projects/:projectId/assessment/:assessmentId',
    component: PgAssessment,
    beforeEnter: authGuard,
    meta: { label: 'Assessment', roleFrom: UserRole.CONTRIBUTOR },
    redirect: {
      name: 'taxonomyAssessmentBusinessActivitiesSectorActivities',
    },
    children: [
      {
        name: 'taxonomyAssessmentBusinessActivities',
        path: 'businessActivities',
        component: OgBusinessActivities,
        meta: { isShown: () => false },
        redirect: {
          name: 'taxonomyAssessmentBusinessActivitiesSectorActivities',
        },
        children: [
          {
            name: 'taxonomyAssessmentBusinessActivitiesSectorActivities',
            path: 'sectors',
            component: TmBusinessActivitiesSectorsActivities,
            meta: { isShown: () => false },
          },
          {
            name: 'taxonomyAssessmentBusinessActivitiesTurnover',
            path: 'turnover',
            component: TmBusinessActivitiesSectorsTurnover,
            meta: { isShown: () => false },
          },
          {
            name: 'taxonomyAssessmentBusinessActivitiesCapex',
            path: 'capex',
            component: TmBusinessActivitiesSectorsCapex,
            meta: { isShown: () => false },
          },
          {
            name: 'taxonomyAssessmentBusinessActivitiesOpex',
            path: 'opex',
            component: TmBusinessActivitiesSectorsOpex,
            meta: { isShown: () => false },
          },
        ],
      },
      {
        name: 'taxonomyAssessmentSubstantialContribution',
        path: 'substantialContribution',
        component: OgSubstantialContribution,
        redirect: {
          name: 'taxonomyAssessmentSubstantialContributionObjectives',
        },
        meta: { isShown: () => false },
        children: [
          {
            name: 'taxonomyAssessmentSubstantialContributionObjectives',
            path: 'objectives',
            component: TmSubstantialContributionObjectives,
            meta: { isShown: () => false },
          },
          {
            name: 'taxonomyAssessmentSubstantialContributionActivityFit',
            path: 'activityFit',
            component: TmSubstantialContributionActivityFit,
            meta: { isShown: () => false },
          },
        ],
      },
      {
        name: 'taxonomyAssessmentDoNoSignificantHarm',
        path: 'doNoSignificantHarm',
        component: OgDoNoSignificantHarm,
        meta: { isShown: () => false },
        redirect: {
          name: 'taxonomyAssessmentDoNoSignificantHarmChecklist',
        },
        children: [
          {
            name: 'taxonomyAssessmentDoNoSignificantHarmChecklist',
            path: 'checklist',
            component: TmDoNoSignificantHarmChecklist,
            meta: { isShown: () => false },
          },
        ],
      },
      {
        name: 'taxonomyAssessmentMinimumSafeguards',
        path: 'minimumSafeguards',
        component: OgMinimumSafeguards,
        meta: { isShown: () => false },
        redirect: {
          name: 'taxonomyAssessmentMinimumSafeguardsChecklist',
        },
        children: [
          {
            name: 'taxonomyAssessmentMinimumSafeguardsChecklist',
            path: 'checklist',
            component: TmMinimumSafeguardsChecklist,
            meta: { isShown: () => false },
          },
        ],
      },
      {
        name: 'taxonomyAssessmentDocuments',
        path: 'documents',
        component: OgDocuments,
        meta: { isShown: () => false },
        redirect: {
          name: 'taxonomyAssessmentDocumentsUpload',
        },
        children: [
          {
            name: 'taxonomyAssessmentDocumentsUpload',
            path: 'upload',
            component: TmDocumentsUpload,
            meta: { isShown: () => false },
          },
        ],
      },
    ],
  },

  // External pages for taxonomy assessments
  {
    name: 'externalTaxonomyAssessmentLogin',
    path: '/external-taxonomy-assessment/login/:shareToken',
    component: PgExternalTaxonomyAssessmentLogin,
  },
  {
    name: 'externalTaxonomyAssessmentAnswer',
    path: '/external-taxonomy-assessment/answer/:shareToken',
    component: PgExternalTaxonomyAssessmentAnswer,
    meta: { isShown: () => false },
  },

  // Double Materiality
  {
    name: 'doubleMateriality',
    path: '/modules/double-materiality',
    component: OgDoubleMateriality,
    beforeEnter: authGuard,
    redirect: {
      name: 'doubleMaterialityStart',
    },
    meta: { label: 'Double Materiality', roleFrom: UserRole.CONTRIBUTOR },
    children: [
      {
        name: 'doubleMaterialityStart',
        path: 'start',
        component: PgDoubleMaterialityStart,
        redirect: {
          name: 'doubleMaterialityStartGuidance',
        },
        meta: { label: 'Start', showInCommandPalette: true },
        children: [
          {
            name: 'doubleMaterialityStartGuidance',
            path: 'guidance',
            component: PgDoubleMaterialityStartGuidance,
            meta: { isShown: () => false },
          },
          {
            name: 'doubleMaterialityStartTeam',
            path: 'team',
            component: PgDoubleMaterialityStartTeam,
            meta: { isShown: () => false, roleFrom: UserRole.MANAGER },
          },
          {
            name: 'doubleMaterialityStartScalesAndCoreData',
            path: 'scale-and-core-data',
            component: PgDoubleMaterialityStartScalesAndCoreData,
            meta: { isShown: () => false },
          },
        ],
      },
      {
        name: 'doubleMaterialityTopics',
        path: 'topics',
        component: PgDoubleMaterialityTopics,
        redirect: {
          name: 'doubleMaterialityTopicsSelection',
        },
        meta: { label: 'Topics' },
        children: [
          {
            name: 'doubleMaterialityTopicsSelection',
            path: 'selection',
            component: PgDoubleMaterialityTopicsSelection,
            meta: { isShown: () => false },
          },
        ],
      },
      {
        name: 'doubleMaterialityIROs',
        path: 'iros',
        component: PgDoubleMaterialityIROs,
        redirect: {
          name: 'doubleMaterialityIROsImpacts',
        },
        meta: { label: 'IROs' },
        children: [
          {
            name: 'doubleMaterialityIROsImpacts',
            path: 'impacts',
            component: PgDoubleMaterialityIROsImpacts,
            meta: { isShown: () => false },
          },
          {
            name: 'doubleMaterialityIROsRisksAndOpportunities',
            path: 'risks-and-opportunities',
            component: PgDoubleMaterialityIROsRisksAndOpportunities,
            meta: { isShown: () => false },
          },
          {
            name: 'doubleMaterialityIROsScore',
            path: 'score',
            component: PgDoubleMaterialityIROsScore,
            meta: { isShown: () => false },
            children: [
              {
                name: 'doubleMaterialityIROsScoreActualNegative',
                path: 'actual-negative',
                component: PgDoubleMaterialityIROsScoreActualNegative,
                meta: { isShown: () => false },
              },
              {
                name: 'doubleMaterialityIROsScorePotentialNegative',
                path: 'potential-negative',
                component: PgDoubleMaterialityIROsScorePotentialNegative,
                meta: { isShown: () => false },
              },
              {
                name: 'doubleMaterialityIROsScoreActualPositive',
                path: 'actual-positive',
                component: PgDoubleMaterialityIROsScoreActualPositive,
                meta: { isShown: () => false },
              },
              {
                name: 'doubleMaterialityIROsScorePotentialPositive',
                path: 'potential-positive',
                component: PgDoubleMaterialityIROsScorePotentialPositive,
                meta: { isShown: () => false },
              },
              {
                name: 'doubleMaterialityIROsScoreRisksAndOpportunities',
                path: 'risks-and-opportunities',
                component: PgDoubleMaterialityIROsScoreRisksAndOpportunities,
                meta: { isShown: () => false },
              },
            ],
          },
        ],
      },
      {
        name: 'doubleMaterialityStakeholders',
        path: 'stakeholders',
        component: PgDoubleMaterialityStakeholders,
        redirect: {
          name: 'doubleMaterialityStakeholdersStakeholders',
        },
        meta: { label: 'Stakeholders' },
        children: [
          {
            name: 'doubleMaterialityStakeholdersStakeholders',
            path: 'stakeholders',
            component: PgDoubleMaterialityStakeholdersStakeholders,
            meta: { isShown: () => false },
          },
          {
            name: 'doubleMaterialityStakeholdersSurveys',
            path: 'surveys',
            component: PgDoubleMaterialityStakeholdersSurveys,
            meta: { isShown: () => false },
          },
          {
            name: 'doubleMaterialityStakeholdersResults',
            path: 'results',
            component: PgDoubleMaterialityStakeholdersResults,
            meta: { isShown: () => false },
          },
        ],
      },
      {
        name: 'doubleMaterialityResults',
        path: 'results',
        component: PgDoubleMaterialityResults,
        redirect: {
          name: 'doubleMaterialityResultsList',
        },
        meta: { label: 'Results' },
        children: [
          {
            name: 'doubleMaterialityResultsList',
            path: 'list',
            component: PgDoubleMaterialityResultsList,
            meta: { isShown: () => false },
          },
          {
            name: 'doubleMaterialityResultsMatrix',
            path: 'matrix',
            component: PgDoubleMaterialityResultsMatrix,
            meta: { isShown: () => false },
          },
          {
            name: 'doubleMaterialityResultsRecommendedESRS',
            path: 'recommended-esrs',
            component: PgDoubleMaterialityResultsRecommendedESRS,
            meta: { isShown: () => false },
          },
        ],
      },
    ],
  },

  // External pages for DMA survey
  {
    name: 'externalDoubleMaterialitySurveyLogin',
    path: '/external-dma-survey/login/:shareToken',
    component: PgExternalDoubleMaterialitySurveyLogin,
  },
  {
    name: 'externalDoubleMaterialitySurveyAnswer',
    path: '/external-dma-survey/answer/:shareToken',
    component: PgExternalDoubleMaterialitySurveyAnswer,
    meta: { isShown: () => false },
  },
];
