<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CheckCircleIcon from '@heroicons/vue/outline/CheckCircleIcon';
import PlusIcon from '@heroicons/vue/solid/PlusIcon';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';

const value = defineModel<string>({
  required: true,
});

const { t } = useI18n();

const showDialog = ref(false);
const text = ref('');

watch(value, (newValue) => {
  text.value = newValue ?? '';
}, { immediate: true });

const isValid = computed(() => text.value.trim().length > 0 && text.value.trim() !== value.value?.trim());

function handleAddExplanationToggle() {
  showDialog.value = !showDialog.value;
}

function handleSubmit() {
  if (isValid.value) {
    value.value = text.value.trim();
    handleAddExplanationToggle();
  }
}
</script>

<template>
  <button
    type="button"
    aria-label="add explanation"
    class="flex items-center text-primary gap-2 py-2"
    @click.prevent="handleAddExplanationToggle"
  >
    <CheckCircleIcon v-if="value" class="h-4 text-emerald-500" />
    <PlusIcon v-else class="h-4" />
    {{ t('Add explanation') }}
  </button>

  <MlDialog
    :isOpen="showDialog"
    :closeOnOutside="false"
    @close="handleAddExplanationToggle"
  >
    <template #title>
      {{ t('Add details about why you selected this person') }}
    </template>

    <template #default="{ close }">
      <form :key="value" class="min-w-[600px] pt-9" @submit.prevent="handleSubmit">
        <hr class="mb-4" />
        <p class="mb-5 text-sm text-gray-500">
          {{ t("Keep it brief but informative: Share why this person was chosen to represent your stakeholder—whether it's their organization, unique insights on the topic, or their collaboration history with your team.") }}
        </p>
        <textarea
          v-model="text"
          class="mb-11 border border-gray-400 w-full rounded-md shadow-sm py-2 px-3 resize-none min-h-[100px] sm:text-sm focus:outline focus:outline-[0.5px] focus:outline-primary focus:border-primary"
          :placeholder="t('Type here')"
        />
        <div class="flex space-x-2 justify-between">
          <AtButton type="button" variant="outline" @click="close">
            {{ t('Close') }}
          </AtButton>
          <AtButton type="submit" :disabled="!isValid">
            {{ t(value ? 'Update' : 'Save') }}
          </AtButton>
        </div>
      </form>
    </template>
  </MlDialog>
</template>
