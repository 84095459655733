import { notify } from '@kyvg/vue3-notification';
import { ApolloError } from '@apollo/client/core';
import { useI18n } from 'vue-i18n';
import useDeleteInsightMutation from '@/api/mutations/DataPointType/deleteInsight.mutation';
import useConfirmViaDialog, { type TDialogData } from '@/utils/composables/useConfirmViaDialog';

export const useDeleteInsight = (...mutationOptions: Parameters<typeof useDeleteInsightMutation>) => {
  const { t } = useI18n();

  const { mutate } = useDeleteInsightMutation(...mutationOptions);
  const { confirmViaDialog } = useConfirmViaDialog();

  const deleteInsight = async (id: string, dialogOptions = {} as TDialogData) => {
    const isConfirmed = await confirmViaDialog({
      title: t('Are you sure you want to delete this insight?'),
      confirmLabel: t('Delete'),
      cancelLabel: t('Cancel'),
      confirmButtonVariant: 'destructive',
      ...dialogOptions,
    });

    if (!isConfirmed) return;

    try {
      return mutate({ deleteInsightInput: { _id: id } });
    } catch (e) {
      if (e instanceof ApolloError) {
        notify({
          type: 'error',
          text: t(e.message),
          duration: 10000,
        });
      }
    }
  };

  return { deleteInsight };
};
