<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import isNil from 'lodash/isNil';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { useStore } from '../../store';

type ResultCalculationForm = {
  internal: string;
};

export type SaveEvent = {
  internal: number;
  stakeholders: number;
};

const emit = defineEmits<{
  close: [];
  save: [event: SaveEvent];
}>();

const { t } = useI18n();
const store = useStore();

const form = reactive<ResultCalculationForm>({
  internal: '80',
});

watch(() => store.value.resultCalculation.internal, (newInternalValue) => {
  form.internal = !isNil(newInternalValue) ? (newInternalValue * 100).toString() : '80';
}, { immediate: true });

const percentageOptions = computed(() => {
  const result: Record<string, string> = {};

  for (let i = 5; i <= 100; i += 5) {
    result[i.toString()] = `${i.toString()}%`;
  }

  return result;
});
const stakeholdersPercentage = computed(() => {
  if (form.internal) {
    return `${100 - parseInt(form.internal, 10)}%`;
  }

  return '100%';
});

function handleClose() {
  emit('close');
}

function handleSubmit() {
  const internal = parseInt(form.internal, 10) / 100;

  emit('save', {
    internal,
    stakeholders: 1 - internal,
  });
}
</script>

<template>
  <MlDialog
    isOpen
    :closeOnOutside="false"
    @close="handleClose"
  >
    <template #title>
      {{ t('Calculation of final materiality results') }}
    </template>

    <template #default="{ close }">
      <form class="pt-9" @submit.prevent="handleSubmit">
        <hr class="mb-8" />
        <p class="text-xs mb-14">
          {{ t('You can customize the percentage impact of stakeholder results on the final score. We recommend assigning an 80% weight to internal results and a 20% weight to stakeholder results.') }}
        </p>
        <div class="flex items-center space-x-10 mb-14">
          <div class="flex items-center space-x-4">
            <p class="font-medium">
              {{ t('Internal:') }}
            </p>
            <MlSelect
              v-model="form.internal"
              usePortal
              wrapperClass="w-24"
              :options="percentageOptions"
              :placeholder="t('Select')"
            />
          </div>
          <div class="flex items-center space-x-4">
            <p class="font-medium">
              {{ t('Stakeholders:') }}
            </p>
            <AtInput wrapperClass="w-20" disabled :modelValue="stakeholdersPercentage" />
          </div>
        </div>
        <div class="flex space-x-2 justify-between">
          <AtButton type="button" variant="outline" @click="close">
            {{ t('Close') }}
          </AtButton>
          <AtButton type="submit">
            {{ t('Save') }}
          </AtButton>
        </div>
      </form>
    </template>
  </MlDialog>
</template>
