import { gql } from '@apollo/client/core';

export default gql`
  query OgRecommendedESRS {
    getDataPointCategoriesForDoubleMateriality {
      _id
      name
      slug
      doubleMaterialityTopic {
        _id
      }
      subcategories {
        _id
        name
        slug
        doubleMaterialityMandatoryReason
      }
    }
  }
`;
