<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import PlusIcon from '@heroicons/vue/solid/PlusIcon';
import { DoubleMaterialitySurveyStatus, DoubleMaterialitySurveyType } from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useDoubleMaterialitySendSurveysMutation from '@/api/mutations/DoubleMateriality/sendSurveys.mutation';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { STAKEHOLDER_ENGAGE_SURVEY_NAME } from '../../utils';
import { useDoubleMaterialityId, useStore, useStoreMethods } from '../../store';
import MlInfo from '../../MlInfo.vue';
import OgExplanation from '../../OgExplanation.vue';
import OgTable, { type Header } from '../../OgTable.vue';
import OgImpactsSurveyTemplatePreview, {
  type SaveEvent as ImpactsTemplatePreviewSaveEvent,
} from './OgImpactsSurveyTemplatePreview.vue';
import OgRandomize, { type SaveEvent as RandomizeSaveEvent } from './OgRandomize.vue';
import OgRisksAndOpportunitiesSurveyTemplatePreview, {
  type SaveEvent as RisksAndOpportunitiesTemplatePreviewSaveEvent,
} from './OgRisksAndOpportunitiesSurveyTemplatePreview.vue';

type RowItem = {
  _id: string;
  index: number;
};

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const { currentUser } = useCurrentUser();
const doubleMaterialityId = useDoubleMaterialityId();
const {
  addInitialSurveySettings,
  addSurvey,
  changeSurvey,
  removeSurvey,
} = useStoreMethods();

const { mutate: sendSurveys, loading: isSendingSurveys } = useDoubleMaterialitySendSurveysMutation();
const { mutate: sendSurveyReminders, loading: isSendingSurveyReminders } = useDoubleMaterialitySendSurveysMutation();

const isImpactsSurveyTemplatePreviewOpen = ref(false);
const isRisksAndOpportunitiesSurveyTemplatePreviewOpen = ref(false);
const isRandomizeOpen = ref(false);
const noCheckboxSelectedButSubmitting = ref(false);

const toRemindChecked = reactive<Record<string, boolean>>({});

const headers = computed<Header[]>(() => [
  { text: '', value: 'select', columnClasses: 'w-8' },
  { text: t('Stakeholder'), value: 'stakeholder', columnClasses: 'w-[300px]' },
  { text: t('Full name'), value: 'fullName' },
  { text: t('Email address'), value: 'email' },
  { text: t('Type of survey'), value: 'type', tooltip: t('You can either select this manually or allow for randomized allocation of surveys per type'), columnClasses: 'w-56' },
  { text: t('Status'), value: 'status', columnClasses: 'w-40' },
]);
const items = computed<RowItem[]>(() => store.value.surveys.map((survey, index) => ({
  _id: survey._id,
  index,
})));

const stakeholderOptions = computed(() => store.value.stakeholders.reduce<Record<string, string>>((acc, stakeholder) => {
  if (stakeholder.stakeholder && stakeholder.engage === STAKEHOLDER_ENGAGE_SURVEY_NAME) {
    acc[stakeholder._id] = t(stakeholder.stakeholder);
  }

  return acc;
}, {}));
const typeOptions = computed(() => ({
  [DoubleMaterialitySurveyType.IMPACT]: t('Impact'),
  [DoubleMaterialitySurveyType.RISKS_OPPORTUNITIES]: t('Risks/opportunities'),
}));
const language = computed(() => currentUser.value?.language ?? 'EN');

const surveySettings = computed(() => {
  return {
    impactsSurveyTemplate: {
      body: store.value.surveySettings.impactsSurveyTemplate?.body[language.value] ?? '',
      subject: store.value.surveySettings.impactsSurveyTemplate?.subject[language.value] ?? '',
    },
    risksAndOpportunitiesSurveyTemplate: {
      body: store.value.surveySettings.risksAndOpportunitiesSurveyTemplate?.body[language.value] ?? '',
      subject: store.value.surveySettings.risksAndOpportunitiesSurveyTemplate?.subject[language.value] ?? '',
    },
  };
});

const isRandomizeDisabled = computed(() => {
  return !store.value.surveys.every((survey) => survey.email && survey.stakeholderId);
});

const canSendSurveys = computed(() => Object.keys(toRemindChecked).some((key) => toRemindChecked[key]));
const isAllToRemindChecked = computed(() => items.value.every((item) => toRemindChecked[item._id]));

const isRemindDisabled = computed(() => !store
  .value
  .surveys
  .some((survey) => survey.status === DoubleMaterialitySurveyStatus.IN_PROGRESS && toRemindChecked[survey._id]));

watch(() => store.value.surveys.length === 0, (hasNoSurveys) => {
  if (hasNoSurveys) {
    addSurvey();
    addSurvey();
  }
}, { immediate: true });

watch(
  [
    () => Object.keys(store.value.surveySettings).length === 0,
    () => currentUser.value?.entity.organization?.name ?? null,
  ],
  ([hasNoSurveySettings, organizationName]) => {
    if (hasNoSurveySettings && organizationName) {
      addInitialSurveySettings(organizationName);
    }
  },
  { immediate: true },
);

watch(items, (newItems) => {
  Object.keys(toRemindChecked).forEach((key) => {
    delete toRemindChecked[key];
  });

  newItems.forEach((item) => {
    toRemindChecked[item._id] = false;
  });
}, { immediate: true });

watch([canSendSurveys, noCheckboxSelectedButSubmitting], ([newCanSendSurveys, newNoCheckboxSelectedButSubmitting]) => {
  if (newNoCheckboxSelectedButSubmitting && newCanSendSurveys) {
    noCheckboxSelectedButSubmitting.value = false;
  }
});

function handleRandomizeToggle() {
  isRandomizeOpen.value = !isRandomizeOpen.value;
}

function handleRandomizeSave(event: RandomizeSaveEvent) {
  if (store.value) {
    store.value.surveys.forEach((survey, index) => {
      changeSurvey(index, 'type', event[survey._id] ?? null);
    });

    handleRandomizeToggle();
  }
}

function handleIsAllToRemindCheckedToggle(event: boolean) {
  items.value.forEach((item) => {
    toRemindChecked[item._id] = event;
  });
}

async function handleRemindClick() {
  const sendSurveyRemindersInput: {
    _id: string;
    email: string;
    fullName: string;
    subject: string;
    body: string;
  }[] = [];

  store.value.surveys.forEach((survey) => {
    if (
      toRemindChecked[survey._id]
      && survey.email
      && survey.fullName
      && survey.type
      && survey.status === DoubleMaterialitySurveyStatus.IN_PROGRESS
    ) {
      sendSurveyRemindersInput.push({
        _id: survey._id,
        email: survey.email,
        fullName: survey.fullName,
        subject: store
          .value
          .surveySettings[
            survey.type === DoubleMaterialitySurveyType.IMPACT ? 'impactsSurveyTemplate' : 'risksAndOpportunitiesSurveyTemplate'
          ]
          ?.subject[currentUser.value!.language] ?? '',
        body: store
          .value
          .surveySettings[
            survey.type === DoubleMaterialitySurveyType.IMPACT ? 'impactsSurveyTemplate' : 'risksAndOpportunitiesSurveyTemplate'
          ]
          ?.body[currentUser.value!.language] ?? '',
      });
    }
  });

  if (sendSurveyRemindersInput.length > 0) {
    try {
      await sendSurveyReminders({
        doubleMaterialityId: doubleMaterialityId.value!,
        sendSurveys: sendSurveyRemindersInput.map((item) => ({
          email: item.email,
          fullName: item.fullName,
          subject: item.subject,
          body: item.body,
          surveyId: item._id,
        })),
      });
    } catch {
      notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
    }
  } else {
    noCheckboxSelectedButSubmitting.value = true;
  }
}

function handleStakeholderChange(index: number, event: string | null) {
  changeSurvey(index, 'stakeholderId', event);
}

function handleEmailChange(index: number, event: string) {
  changeSurvey(index, 'email', event);
}

function handleFullNameChange(index: number, event: string) {
  changeSurvey(index, 'fullName', event);
}

function handleTypeChange(index: number, event: DoubleMaterialitySurveyType | null) {
  changeSurvey(index, 'type', event);
}

function handleRemoveRow(event: number) {
  removeSurvey(event);
}

function handleAddRowClick() {
  addSurvey();
}

function handleImpactsSurveyTemplatePreviewToggle() {
  isImpactsSurveyTemplatePreviewOpen.value = !isImpactsSurveyTemplatePreviewOpen.value;
}

function handleImpactsSurveyTemplatePreviewSave(event: ImpactsTemplatePreviewSaveEvent) {
  if (store.value.surveySettings.impactsSurveyTemplate) {
    store.value.surveySettings.impactsSurveyTemplate.subject[language.value] = event.subject;
    store.value.surveySettings.impactsSurveyTemplate.body[language.value] = event.body;
    handleImpactsSurveyTemplatePreviewToggle();
  }
}

function handleRisksAndOpportunitiesSurveyTemplatePreviewToggle() {
  isRisksAndOpportunitiesSurveyTemplatePreviewOpen.value = !isRisksAndOpportunitiesSurveyTemplatePreviewOpen.value;
}

function handleRisksAndOpportunitiesSurveyTemplatePreviewSave(event: RisksAndOpportunitiesTemplatePreviewSaveEvent) {
  if (store.value.surveySettings.risksAndOpportunitiesSurveyTemplate) {
    store.value.surveySettings.risksAndOpportunitiesSurveyTemplate.subject[language.value] = event.subject;
    store.value.surveySettings.risksAndOpportunitiesSurveyTemplate.body[language.value] = event.body;
    handleRisksAndOpportunitiesSurveyTemplatePreviewToggle();
  }
}

async function handleSendSurveysClick() {
  const sendSurveysInput: {
    _id: string;
    email: string;
    fullName: string;
    subject: string;
    body: string;
  }[] = [];

  store.value.surveys.forEach((survey) => {
    if (toRemindChecked[survey._id] && survey.email && survey.fullName && survey.type) {
      sendSurveysInput.push({
        _id: survey._id,
        email: survey.email,
        fullName: survey.fullName,
        subject: store
          .value
          .surveySettings[
            survey.type === DoubleMaterialitySurveyType.IMPACT ? 'impactsSurveyTemplate' : 'risksAndOpportunitiesSurveyTemplate'
          ]
          ?.subject[currentUser.value!.language] ?? '',
        body: store
          .value
          .surveySettings[
            survey.type === DoubleMaterialitySurveyType.IMPACT ? 'impactsSurveyTemplate' : 'risksAndOpportunitiesSurveyTemplate'
          ]
          ?.body[currentUser.value!.language] ?? '',
      });
    }
  });

  if (sendSurveysInput.length > 0) {
    try {
      await sendSurveys({
        doubleMaterialityId: doubleMaterialityId.value!,
        sendSurveys: sendSurveysInput.map((item) => ({
          email: item.email,
          fullName: item.fullName,
          subject: item.subject,
          body: item.body,
          surveyId: item._id,
        })),
      });

      sendSurveysInput.forEach((sendSurvey) => {
        const survey = store.value.surveys.find((item) => item._id === sendSurvey._id);
        if (survey) {
          survey.status = DoubleMaterialitySurveyStatus.IN_PROGRESS;
        }
      });
    } catch {
      notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
    }
  } else {
    noCheckboxSelectedButSubmitting.value = true;
  }
}
</script>

<template>
  <MlInfo type="warning" class="mb-7">
    <p>
      {{
        t('Please compile the accurate contact details for all individuals in your company’s key stakeholder groups, which you selected in the previous step. These contacts will be approached for their feedback on your preliminary DMA results.')
      }}
    </p>
  </MlInfo>

  <div class="flex flex-wrap lg:flex-nowrap gap-10 mb-6">
    <div
      class="flex-initial w-full lg:w-1/2 border rounded-lg p-6 cursor-pointer hover:bg-blue-50 hover:border-blue-600"
      @click="handleImpactsSurveyTemplatePreviewToggle"
    >
      <p class="mb-12">
        {{ t('Impact survey') }}
      </p>
      <AtButton type="button">
        {{ t('Preview') }}
      </AtButton>
    </div>
    <div
      class="flex-initial w-full lg:w-1/2 border rounded-lg p-6 cursor-pointer hover:bg-blue-50 hover:border-blue-600"
      @click="handleRisksAndOpportunitiesSurveyTemplatePreviewToggle"
    >
      <p class="mb-12">
        {{ t('Risks and opportunities survey') }}
      </p>
      <AtButton type="button">
        {{ t('Preview') }}
      </AtButton>
    </div>
  </div>

  <div class="flex justify-end space-x-5 mb-7">
    <AtButton type="button" variant="outline" :disabled="isRandomizeDisabled" @click="handleRandomizeToggle">
      {{ t('Randomize recipients per type') }}
    </AtButton>
    <AtButton
      type="button"
      :disabled="isRemindDisabled"
      :loading="isSendingSurveyReminders"
      @click="handleRemindClick"
    >
      {{ t('Remind') }}
    </AtButton>
  </div>

  <OgTable
    :headers="headers"
    :items="items"
    @removeRow="handleRemoveRow"
  >
    <template #header-select>
      <AtCheckbox
        squared
        :size="CheckboxSize.XS"
        :checked="isAllToRemindChecked"
        :inputClass="noCheckboxSelectedButSubmitting ? 'border-error' : ''"
        @toggleCheckbox="handleIsAllToRemindCheckedToggle"
      />
    </template>
    <template #item-select="row: RowItem">
      <AtCheckbox
        squared
        :size="CheckboxSize.XS"
        :checked="!!toRemindChecked[row._id]"
        :inputClass="noCheckboxSelectedButSubmitting ? 'border-error' : ''"
        @toggleCheckbox="toRemindChecked[row._id] = $event"
      />
    </template>
    <template #item-stakeholder="row: RowItem">
      <MlSelect
        :modelValue="store.surveys[row.index].stakeholderId ?? undefined"
        :options="stakeholderOptions"
        :placeholder="t('Select')"
        @update:modelValue="handleStakeholderChange(row.index, $event)"
      />
    </template>
    <template #item-fullName="row: RowItem">
      <AtInput
        :modelValue="store.surveys[row.index].fullName"
        type="text"
        :placeholder="t('Enter first and last name')"
        @update:modelValue="handleFullNameChange(row.index, $event?.trim() ?? '')"
      />
    </template>
    <template #item-email="row: RowItem">
      <AtInput
        :modelValue="store.surveys[row.index].email"
        type="email"
        :placeholder="t('Enter email address')"
        @update:modelValue="handleEmailChange(row.index, $event?.trim() ?? '')"
      />
    </template>
    <template #item-type="row: RowItem">
      <MlSelect
        :modelValue="store.surveys[row.index].type ?? undefined"
        :options="typeOptions"
        :placeholder="t('Select')"
        @update:modelValue="handleTypeChange(row.index, $event ?? null)"
      />
    </template>
    <template #item-status="row: RowItem">
      <AtBadge
        v-if="store.surveys[row.index].status === DoubleMaterialitySurveyStatus.COMPLETED"
        type="EMERALD"
        size="sm"
      >
        {{ t('Completed') }}
      </AtBadge>
      <AtBadge
        v-else-if="store.surveys[row.index].status === DoubleMaterialitySurveyStatus.IN_PROGRESS"
        type="warning"
        size="sm"
      >
        {{ t('In progress') }}
      </AtBadge>
      <AtBadge
        v-else-if="store.surveys[row.index].status === DoubleMaterialitySurveyStatus.NOT_SENT"
        type="error"
        size="sm"
      >
        {{ t('Not sent') }}
      </AtBadge>
      <AtBadge
        v-else
        type="neutral"
        size="sm"
      >
        {{ t('n/a') }}
      </AtBadge>
    </template>
    <template #expand="row: RowItem">
      <OgExplanation v-model="store.surveys[row.index].explanation" />
    </template>
    <template #footer>
      <button
        type="button"
        aria-label="add rows"
        class="flex items-center text-gray-400 text-xs gap-2 hover:text-primary"
        @click.prevent="handleAddRowClick"
      >
        <PlusIcon class="h-4" />
        {{ t('Add rows') }}
      </button>
    </template>
  </OgTable>

  <div class="flex items-center justify-between gap-4">
    <AtButton
      type="button"
      variant="outline"
      @click="router.push({ name: 'doubleMaterialityStakeholdersStakeholders' })"
    >
      {{ t('Go back') }}
    </AtButton>
    <AtButton
      type="button"
      :class="canSendSurveys ? '' : 'opacity-50'"
      :loading="isSendingSurveys"
      @click="handleSendSurveysClick"
    >
      {{ ('Send out the surveys') }}
    </AtButton>
  </div>

  <OgImpactsSurveyTemplatePreview
    v-if="isImpactsSurveyTemplatePreviewOpen"
    :initialSubject="surveySettings.impactsSurveyTemplate.subject"
    :initialBody="surveySettings.impactsSurveyTemplate.body"
    @close="handleImpactsSurveyTemplatePreviewToggle"
    @save="handleImpactsSurveyTemplatePreviewSave"
  />

  <OgRisksAndOpportunitiesSurveyTemplatePreview
    v-if="isRisksAndOpportunitiesSurveyTemplatePreviewOpen"
    :initialSubject="surveySettings.risksAndOpportunitiesSurveyTemplate.subject"
    :initialBody="surveySettings.risksAndOpportunitiesSurveyTemplate.body"
    @close="handleRisksAndOpportunitiesSurveyTemplatePreviewToggle"
    @save="handleRisksAndOpportunitiesSurveyTemplatePreviewSave"
  />

  <OgRandomize
    v-if="isRandomizeOpen"
    @close="handleRandomizeToggle"
    @save="handleRandomizeSave"
  />
</template>
