<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const emit = defineEmits<{
  start: [];
}>();

const { t } = useI18n();

function handleStartClick() {
  emit('start');
}
</script>

<template>
  <div>
    <p class="mb-10">
      {{ t('You have been invited to help the company decide which parameters of environmental, social and governance metrics they need to measure. Please take this survey and vote on the significance of certain impacts, risks and opportunities.') }}
    </p>

    <AtButton type="button" @click="handleStartClick">
      {{ t('Get started') }}
    </AtButton>
  </div>

  <div>
    <p class="text-sm mb-1 font-medium">
      {{ t('About Codio Impact') }}
    </p>
    <p class="text-xs">
      {{ t('We automate the way you manage and report your Environmental, Social, Governance (ESG) data, while remaining fully compliant with the newest regulation and leading standards.') }}
    </p>
  </div>
</template>
