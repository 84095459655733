<script setup lang="ts">

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import { TaxonomyFitEnum } from '@/__generated__/types';
import MlEligibleAlignedCell from '../MlEligibleAlignedCell.vue';
import type { TaxonomyProject } from './types';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  project: TaxonomyProject
}

const activities = computed(() => props.project.assessments[0].businessActivities ?? []);
const turnover = computed(() => props.project.assessments[0].turnover);

const headers = computed(() => [
  { text: t('Business Activity'), value: 'activity', sortable: true, filterable: true },
  { text: t(''), value: 'fit', sortable: true, filterable: true },
  { text: t('Turnover'), value: 'turnover', sortable: true },
  { text: t('Eligible'), value: 'eligible', sortable: true, filterable: true },
  { text: t('Aligned'), value: 'aligned', sortable: true, filterable: true },
]);

</script>

<template>
  <OgDataTable
    :headers="headers"
    :items="activities"
    controlsHidden
  >
    <template #item-activity="row">
      <span class="line-clamp-2 hover:line-clamp-none">
        {{ t(row.activity.name) }}
      </span>
    </template>
    <template #item-fit="row">
      <AtBadge
        v-if="row.taxonomyFit === TaxonomyFitEnum.ALIGNED"
        class="mr-1 py-0 text-xs font-light"
        type="ACCEPTED"
      >
        {{ t('Aligned') }}
      </AtBadge>
      <AtBadge
        v-else-if="row.taxonomyFit === TaxonomyFitEnum.ELIGIBLE"
        class="mr-1 py-0 text-xs font-light"
        type="warning"
      >
        {{ t('Eligible') }}
      </AtBadge>
      <AtBadge
        v-else
        class="mr-1 py-0 text-xs font-light"
        type="neutral"
      >
        {{ t('Not eligible') }}
      </AtBadge>
    </template>
    <template #item-turnover="row">
      <MlEligibleAlignedCell :value="row.activityTurnover" :turnover="turnover" type="total" />
    </template>
    <template #item-eligible="row">
      <MlEligibleAlignedCell :value="row.eligibleTurnover" :turnover="turnover" type="eligible" />
    </template>
    <template #item-aligned="row">
      <MlEligibleAlignedCell :value="row.alignedTurnover" :turnover="turnover" type="aligned" />
    </template>
  </OgDataTable>
</template>
