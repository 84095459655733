<script setup lang="ts">
import type { ApolloError } from '@apollo/client/core';
import { computed, ref, watch, type Ref, type ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChevronDownIcon, ChevronRightIcon, XIcon } from '@heroicons/vue/solid';
import dayjs from '@/lib/dayjs/config';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import OgDateYearPicker from '@/components/organisms/OgDateYearPicker.vue';
import MlModal from '@/components/molecules/MlModal.vue';
import { DataPointTypeRefreshIntervalEnum, DataPointTypeValueUnitEnum } from '@/__generated__/types';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { getAlternativeUnits } from '@/utils/helpers/getAlternativeUnits';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import { getUnitsOptions } from '@/constants/units';
import type { TQuestionDataTableItem, TDataPointTypeOverride } from '../../../types';

export type TEditQuestionPayload = {
  dataPointTypeId: string;
  dataPointRequestId: string;
  refreshInterval: string;
  valueUnit: string;
  isEmissionFactorGlobal: boolean;
  isCustomUnitValueGlobal: boolean;
  emissionFactorYear: number | undefined;
  emissionFactorValue: number | undefined;
  customConversionFactor: number | undefined;
  pastDates: number[];
  deleteDates: number[];
  isSplit: boolean;
  isDelegated: boolean;
  isRemovingDelegation: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  text: '',
  isEmissions: false,
});
type Props = {
  isRevealed: boolean;
  text?: string;
  item: TQuestionDataTableItem | null;
  loading: boolean;
  dataPointTypeOverrides: TDataPointTypeOverride[];
  error: ApolloError | null;
  confirmDisabled?: boolean;
  isSplitInputDataType: boolean;
  isChilds?:boolean;
  isParentWithoutChilds: boolean;
  historicDprs: TQuestionDataTableItem[] | null;
  isEmissions: boolean;
}

const emit = defineEmits<Emits>();
type Emits = {
  (e: 'confirm', payload: TEditQuestionPayload): void
  (e: 'cancel'): void
};

const { t, locale } = useI18n();
const { isAdminOrSuperAdmin } = useCurrentUser();
const isSplit = ref(false);
const units = computed(() => getUnitsOptions(props.item?.dpr.dataPointType.valueUnit as DataPointTypeValueUnitEnum));
const delegations = computed(() => props.item?.dpr.delegations ?? []);

const isRecommendedUnit = computed(() => {
  const recommendedUnits = units.value.find((group) => group.key === 'recommended')?.options.map((option) => option.value);
  return recommendedUnits?.includes(unitSelected.value as DataPointTypeValueUnitEnum);
});

const confirmPayload: ComputedRef<TEditQuestionPayload> = computed(() => {
  return {
    dataPointTypeId: props.item?.dpr.dataPointType._id ?? '',
    dataPointRequestId: props.item?.dpr._id ?? '',
    refreshInterval: refreshIntervalSelected.value,
    customConversionFactor: customConversionFactor.value,
    valueUnit: unitSelected.value,
    isEmissionFactorGlobal: isEmissionFactorGlobal.value,
    isCustomUnitValueGlobal: isCustomUnitValueGlobal.value,
    emissionFactorYear: emissionFactorYearSelected.value ? Number(emissionFactorYearSelected.value) : undefined,
    emissionFactorValue: emissionFactorValue.value,
    pastDates: [...new Set(dates.value)],
    deleteDates: [...new Set(deleteDates.value)],
    isSplit: isSplit.value,
    isDelegated: !!delegations.value.length,
    isRemovingDelegation: isRemovingDelegation.value,
  };
});

const emissionFactors = computed(() => props.item?.dpr?.dataPointType?.emissionFactors);
const override = computed(() => props.dataPointTypeOverrides.find(
  (_override) => _override.datapointtype._id === props.item?.dpr.dataPointType._id,
));

watch(override, () => {
  if (override.value?.isSplit != null) {
    isSplit.value = override.value?.isSplit ?? false;
  }
}, { immediate: true });

const emissionFactorYearOptions = computed(
  () => [
    dayjs.utc(props.item?.dpr.from).year(),
    ...dates.value ?? [],
  ].reduce((a, v) => ({ ...a, [String(v)]: String(v) }), {}),
);

const isEmissionFactorGlobal = ref(false);
const isCustomUnitValueGlobal = ref(false);
const isRemovingDelegation = ref(false);
const emissionFactorValue = ref<number>();
const customConversionFactor = ref<number>();
const emissionFactorYearSelected = ref<string>();
const unitSelected = ref<string>('');
const refreshIntervalSelected = ref<string>('');
const previousUnit = ref(unitSelected.value);

watch(unitSelected, (newValue, oldValue) => {
  previousUnit.value = oldValue;
});

const isUnitValueChanged = computed(() => unitSelected.value !== previousUnit.value
          && unitSelected.value !== ''
          && previousUnit.value !== '',
);

watch(() => props.item, () => {
  if (override.value?.valueUnit) {
    unitSelected.value = override.value.valueUnit;
  } else if (props.item?.dpr.dataPointType.valueUnit) {
    unitSelected.value = props.item?.dpr.dataPointType.valueUnit;
  }

  if (override.value?.customConversionFactor) {
    customConversionFactor.value = override.value.customConversionFactor;
  }

  if (override.value?.customConversionFactor && isRecommendedUnit.value) {
    customConversionFactor.value = 0;
  }

  if (override.value?.refreshInterval) {
    refreshIntervalSelected.value = override.value.refreshInterval;
  } else {
    refreshIntervalSelected.value = props.item?.dpr.dataPointType.refreshInterval ?? '';
  }

  emissionFactorYearSelected.value = String(new Date(props.item?.dpr.from).getFullYear());
}, { immediate: true });

watch(emissionFactorYearSelected, () => {
  const yearSelectedOverrideFactor = override.value?.emissionFactors?.find(
    (ef) => ef.year === Number(emissionFactorYearSelected.value),
  )?.factor;
  const yearSelectedFactor = emissionFactors.value?.find((ef) => ef.year === Number(emissionFactorYearSelected.value))?.factor;

  if (yearSelectedOverrideFactor) {
    emissionFactorValue.value = yearSelectedOverrideFactor;
  } else if (yearSelectedFactor) {
    emissionFactorValue.value = yearSelectedFactor;
  } else {
    emissionFactorValue.value = 0;
  }
}, { immediate: true });

const refreshIntervalOptions = computed(() => ({
  [DataPointTypeRefreshIntervalEnum.ONCE]: t('once'),
  [DataPointTypeRefreshIntervalEnum.DAILY]: t('every day'),
  [DataPointTypeRefreshIntervalEnum.WEEKLY]: t('every week'),
  [DataPointTypeRefreshIntervalEnum.MONTHLY]: t('every month'),
  [DataPointTypeRefreshIntervalEnum.QUARTERLY]: t('every quarter'),
  [DataPointTypeRefreshIntervalEnum.HALF_YEARLY]: t('every 6 months'),
  [DataPointTypeRefreshIntervalEnum.YEARLY]: t('every year'),
}));

const getUnitCode = (unitValue: string) => {
  const selectedUnitObject = units.value
    .flatMap((group) => group.options)
    .find((option) => option.value === unitValue);

  return selectedUnitObject ? selectedUnitObject.unitCode : '';
};

const valueUnitCode = computed(() => getUnitCode(props.item?.dpr?.dataPointType?.valueUnit || ''));
const overrideValueUnitCode = computed(() => getUnitCode(unitSelected.value));

function removePastDate(index: number) {
  deleteDates.value = [...new Set([...deleteDates.value, dates.value[index]])];
  dates.value.splice(index, 1);
}

const deleteDates: Ref<number[]> = ref([]);
const dates: Ref<number[]> = ref(props.historicDprs?.map((dpr) => new Date(dpr.dpr.from).getFullYear()) ?? []);

const isMenuOpen = ref(dates.value.length > 0);

watch(() => dates.value.length, (newVal, oldVal) => {
  if (newVal > oldVal) {
    deleteDates.value = [...new Set(deleteDates.value.filter((deleteDate) => !dates.value.includes(deleteDate)))];
  }
  dates.value = [...new Set(dates.value)];

  if (dates.value.length > 0) {
    isMenuOpen.value = true;
  }
});

const unitOptions = computed(() => {
  if (props.item?.dpr?.dataPointType?.valueUnit) {
    return getAlternativeUnits(props.item.dpr.dataPointType.valueUnit);
  }
  return {};
});

</script>

<template>
  <MlModal
    class="m-6 min-w-[44rem] max-w-xl"
    :isRevealed="props.isRevealed"
    @close="emit('cancel')"
  >
    <slot name="title">
      <h3
        class="text-xl font-medium"
      >
        {{ t('Edit question') }}
      </h3>
    </slot>
    <slot name="text">
      <p class="my-4 w-5/6 font-semibold text-primary">
        {{ props.text }}
      </p>
    </slot>
    <template v-if="delegations.length">
      <h4
        class="pb-1 pt-3 text-sm"
      >
        <span class="font-semibold">
          {{ t('This question is delegated to {email}. In order to be modified, the delegation must be removed first.',
               { email: delegations.map((delegation) => delegation.user.email).join(', ') ?? '' }) }}
        </span><br>
        <AtCheckbox
          wrapperClass=""
          :label="t('Remove the delegation from {email}.', { email: delegations.map((delegation) => delegation.user.email).join(', ') ?? '' })"
          :checked="isRemovingDelegation"
          @toggleCheckbox="isRemovingDelegation = !isRemovingDelegation"
        />
      </h4>
    </template>

    <div v-if="isRemovingDelegation || !delegations.length">
      <template v-if="!isChilds && isAdminOrSuperAdmin">
        <h4
          class="pb-1 pt-3 text-sm font-semibold"
        >
          {{ t('How frequently should the question be shown?') }}
        </h4>
        <MlSelect
          v-model="refreshIntervalSelected"
          :label="t('Refresh Interval')"
          wrapperClass="mb-4 w-2/3"
          sortedOptions
          data-cy="MlSelectRefreshIntervalOgEditQuestionModal"
          :options="refreshIntervalOptions"
        />
        <div
          class="mb-4 grid grid-cols-[auto_20px] rounded-md border p-3"
          :class="[{
            'border-primary bg-blue-50': isMenuOpen,
            'border-gray-400 bg-gray-200': !isMenuOpen,
          }]"
        >
          <div class="mr-2 ">
            <h4
              class="cursor-pointer text-sm font-semibold text-gray-700"
              @click.stop="isMenuOpen = !isMenuOpen"
            >
              {{ t('Add data for past years') }}
            </h4>
            <p
              class="cursor-pointer text-sm text-gray-700"
              @click.stop="isMenuOpen = !isMenuOpen"
            >
              {{ t('This will create a duplicate of the question for selected years in the past, allowing your team to gather data retrospectively.') }}
            </p>
          </div>
          <div
            class="flex cursor-pointer"
            @click.stop="isMenuOpen = !isMenuOpen"
          >
            <ChevronRightIcon
              v-show="!isMenuOpen"
              class="w-5"
            />
            <ChevronDownIcon
              v-show="isMenuOpen"
              class="w-5"
            />
          </div>
          <div v-if="isMenuOpen">
            <h4
              class="mt-3 text-sm font-semibold text-gray-700"
            >
              {{ t('Years:') }}
            </h4>
            <div
              class="grid grid-cols-4 py-1"
            >
              <OgDateYearPicker
                v-for="(date, index) in dates.length + 1"
                :key="index"
                v-model="dates[index]"
                class="mb-1 mr-1"
                :minYear="(new Date()).getFullYear() - 6"
                :maxYear="(new Date()).getFullYear() - 1"
                :lastCloseIconHidden="index === dates.length"
                @removePastDate="removePastDate(index)"
              />
            </div>
          </div>
        </div>
        <hr />
      </template>

      <template v-if="Object.values(unitOptions).length > 0 && isAdminOrSuperAdmin">
        <h4
          class="pb-1 pt-3 text-sm font-semibold"
        >
          {{ t('What unit should the data be submitted in?') }}
        </h4>
        <div class="flex flex-col">
          <div class="flex gap-4 items-end">
            <MlSelect
              v-model="unitSelected"
              :label="t('Unit')"
              wrapperClass="min-w-[200px]"
              data-cy="MlSelectUnitOgEditQuestionModal"
              :groupedOptions="units"
            />
            <template v-if="!isRecommendedUnit && props.item?.dpr.dataPointType.valueUnit !== unitSelected">
              <div>
                <span
                  class="text-sm"
                >
                  {{ t('Conversion factor*') }}
                </span>
                <AtInput
                  v-model="customConversionFactor"
                  wrapperClass="min-w-1 max-w-[12rem]"
                  type="emissionFactor"
                  unit="Units (tCO2eq/x)"
                >
                  <template #unit>
                    <span class="truncate">
                      {{ t('from') }} {{ t(`${valueUnitCode}`) }} {{ t('to') }} {{ t(`${overrideValueUnitCode}`) }}
                    </span>
                  </template>
                </AtInput>
              </div>
              <AtCheckbox
                wrapperClass="ml-auto"
                :label="t('Change for all projects')"
                :checked="isCustomUnitValueGlobal"
                @toggleCheckbox="isCustomUnitValueGlobal = !isCustomUnitValueGlobal"
              />
            </template>
          </div>
          <div v-if="!isRecommendedUnit && props.item?.dpr.dataPointType.valueUnit !== unitSelected" class="flex w-full my-2">
            <span class="text-sm">{{ t('*The accuracy of the conversion factor is solely at your company’s responsibility. Codio Impact holds no liability for the accuracy of the chosen conversion.') }}</span>
          </div>
          <hr class="mt-4" />
        </div>
      </template>

      <template v-if="emissionFactors && props.isEmissions && isAdminOrSuperAdmin">
        <h4
          class="pb-1 pt-3 text-sm font-semibold"
        >
          {{ t('Which emission factor do you want to use to calculate your carbon footprint?') }}
        </h4>
        <div
          class="flex flex-col gap-4"
        >
          <MlSelect
            v-model="emissionFactorYearSelected"
            :placeholder="t('Reporting year')"
            wrapperClass="w-1/2"
            sortedOptions
            :options="emissionFactorYearOptions"
          />
          <div class="flex items-end justify-between">
            <AtInput
              v-model="emissionFactorValue"
              :errors="isUnitValueChanged ? ['Please double check your emission factor'] : []"
              wrapperClass="w-1/2"
              type="emissionFactor"
              unit="Units (tCO2eq/x)"
            >
              <template #unit>
                <span class="truncate">
                  {{ t('Units (tCO2eq/x)') }}
                </span>
              </template>
            </AtInput>
            <AtCheckbox
              wrapperClass=""
              :label="t('Change for all projects')"
              :checked="isEmissionFactorGlobal"
              @toggleCheckbox="isEmissionFactorGlobal = !isEmissionFactorGlobal"
            />
          </div>
          <span
            v-if="isUnitValueChanged"
            class="text-sm mb-2"
          >{{ t(`*This emission factor refers to the original unit:`) }} {{ t(props.item?.dpr.dataPointType.valueUnit || '') }}</span>
        </div>
        <hr />
      </template>
      <div v-if="props.isSplitInputDataType && (isChilds || isParentWithoutChilds)">
        <h4
          class="pb-1 pt-3 text-sm font-semibold"
        >
          {{ t('Enable advanced data categorisation') }}
          <AtTooltipIcon
            :triggers="['click', 'touch']"
            :delay="0"
            autoHide
            class="cursor-pointer"
            @click.stop
          >
            <template #tooltip="{ hide }">
              <div class="flex flex-row-reverse whitespace-pre-line">
                <AtIconButton
                  class="h-6 w-6 shrink-0"
                  :icon="XIcon"
                  :title="t('Close')"
                  @click="hide"
                />
                <img v-if="locale === 'de'" class="max-w-md" src="@/assets/images/numeric-split-de.png" alt="numeric split example image" />
                <img v-else class="max-w-md" src="@/assets/images/numeric-split-en.png" alt="numeric split example image" />
              </div>
            </template>
          </AtTooltipIcon>
        </h4>
        <p class="text-sm text-gray-700 mb-2">
          {{ t('With this option, you can divide questions into multiple answers.') }} <br />
          {{ t('Add categories, enter different data values and even change the reporting unit. This way, you can provide detailed insights.') }}
        </p>
        <AtCheckbox
          :label="t('Enable advanced data categorisation')"
          :checked="isSplit"
          wrapperClass="-ml-1"
          @toggleCheckbox="isSplit = !isSplit"
        />
        <hr class="my-4" />
      </div>
      <div
        class="modal-action flex items-end justify-between align-bottom "
      >
        <AtButton
          variant="outline"
          data-cy="AtButtonCreateEntityModalCancel"
          @click="emit('cancel')"
        >
          {{ t('Cancel') }}
        </AtButton>

        <AtButton
          :disabled="props.confirmDisabled"
          data-cy="AtButtonCreateEntityModalSkipAndSave"
          :loading="props.loading"
          @click.stop="emit('confirm', confirmPayload)"
        >
          {{ t('Save') }}
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
