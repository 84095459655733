import { notify } from '@kyvg/vue3-notification';
import { ApolloError } from '@apollo/client/core';
import { useI18n } from 'vue-i18n';
import useDeleteEntityKPIMutation from '@/api/mutations/EntityKPI/deleteEntityKpi.mutation';
import useConfirmViaDialog, { type TDialogData } from '@/utils/composables/useConfirmViaDialog';

export const useDeleteKPIs = (...mutationOptions: Parameters<typeof useDeleteEntityKPIMutation>) => {
  const { t } = useI18n();

  const { mutate: deleteEntityKpi } = useDeleteEntityKPIMutation(...mutationOptions);
  const { confirmViaDialog } = useConfirmViaDialog();

  const deleteKPIs = async (ids: string[], dialogOptions = {} as TDialogData) => {
    const isConfirmed = await confirmViaDialog({
      title: t('Are you sure you want to delete this KPI?'),
      confirmLabel: t('Delete'),
      cancelLabel: t('Cancel'),
      confirmButtonVariant: 'destructive',
      ...dialogOptions,
    });

    if (!isConfirmed) return;

    try {
      await Promise.all(ids.map((id) => deleteEntityKpi({ deleteEntityKPIInput: { _id: id } })));
    } catch (e) {
      if (e instanceof ApolloError) {
        notify({
          type: 'error',
          text: t(e.message),
          duration: 10000,
        });
      }
    }
  };

  return { deleteKPIs };
};
