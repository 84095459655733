<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import type {
  PgExternalDoubleMaterialitySurveyAnswerQuery,
  PgExternalDoubleMaterialitySurveyAnswerQueryVariables,
} from '@/__generated__/types';
import logo from '@/assets/logo.svg';
import AtNonAuthBackground from '@/components/atoms/AtNonAuthBackground.vue';
import OgLocaleChange from '@/components/organisms/OgLocaleChange.vue';
import { useCreateStore, useIsStoreReady } from '../../store';
import OgStart from './OgStart.vue';
import OgAnswer from './OgAnswer.vue';
import OgDone from './OgDone.vue';
import PG_EXTERNAL_DOUBLE_MATERIALITY_SURVEY_ANSWER_QUERY from './PgExternalDoubleMaterialitySurveyAnswer.query';

enum Step {
  Start,
  Answer,
  Done,
}

const router = useRouter();
const route = useRoute();

const email = window.localStorage.getItem('doubleMaterialitySurveyParticipantEmail') ?? '';
const shareToken = route.params.shareToken.toString();

const { result, error } = useQuery<
  PgExternalDoubleMaterialitySurveyAnswerQuery,
  PgExternalDoubleMaterialitySurveyAnswerQueryVariables
>(PG_EXTERNAL_DOUBLE_MATERIALITY_SURVEY_ANSWER_QUERY, {
  email,
  shareToken,
});

const step = ref(Step.Start);

const showSide = computed(() => step.value !== Step.Answer);

const data = computed(() => result.value?.getExternallyDoubleMateriality ?? null);
const doubleMaterialityId = computed(() => data.value?.doubleMaterialityId ?? '');

useCreateStore(doubleMaterialityId, true);
const isReady = useIsStoreReady(true);

watch(error, (newError) => {
  if (newError) {
    router.replace({
      name: 'signup',
    });
  }
});

function handleStart() {
  step.value = Step.Answer;
}

function handleSaved() {
  step.value = Step.Done;
}
</script>

<template>
  <div class="h-screen">
    <div class="flex h-full">
      <div
        class="p-8 flex flex-col"
        :class="{
          'w-6/12 justify-between': showSide,
          'w-full gap-8': !showSide,
        }"
      >
        <div class="flex items-center justify-between space-x-4">
          <router-link :to="{ name: 'loginStart' }">
            <img
              class="h-12 w-auto"
              :src="logo"
              alt="Codio Impact GmbH"
            >
          </router-link>
          <div>
            <OgLocaleChange />
          </div>
        </div>
        <template v-if="isReady">
          <OgStart v-if="step === Step.Start" @start="handleStart" />
          <OgAnswer
            v-else-if="step === Step.Answer && data"
            :data="data"
            @saved="handleSaved"
          />
          <OgDone v-else />
        </template>
      </div>

      <AtNonAuthBackground v-if="showSide" class="relative hidden w-0 flex-1 lg:block" />
    </div>
  </div>
</template>
