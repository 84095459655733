<script setup lang="ts">

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import OgSimpleTable from '@/components/organisms/OgSimpleTable/OgSimpleTable.vue';
import { router } from '@/router';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import type { TaxonomyProject } from './types';

const props = defineProps<Props>();
type Props = {
  taxonomyProjects: TaxonomyProject[]
};
const { t } = useI18n();

type TProgress = {
  name: string
  eligible: number
  aligned: number
  turnover: number
  activities: Record<string, Omit<TProgress, 'activities'>>
}

const headers = computed(() => [
  t('Project'), t('Eligible'), t('Aligned'),
]);

const taxonomyProjectsProgress = computed(() => props.taxonomyProjects.reduce((acc, curr) => {
  const projectId = curr._id;

  acc[projectId] ??= {
    name: curr.name,
    eligible: 0,
    aligned: 0,
    turnover: 0,
    activities: {},
  };

  if (curr.assessments[0]) {
    acc[projectId].name = curr.name;
    acc[projectId].eligible += curr.assessments[0].eligibleTurnover;
    acc[projectId].aligned += curr.assessments[0].alignedTurnover;
    acc[projectId].turnover += curr.assessments[0].turnover;
    acc[projectId].activities = curr.assessments[0].businessActivities.reduce((_acc, _curr) => {
      const activityId = _curr._id;
      _acc[activityId] ??= {
        name: '',
        eligible: 0,
        aligned: 0,
        turnover: 0,
      };

      _acc[activityId].name += _curr.activity.name;
      _acc[activityId].eligible += _curr.eligibleTurnover;
      _acc[activityId].aligned += _curr.alignedTurnover;
      _acc[activityId].turnover += _curr.activityTurnover;

      return _acc;
    }, {} as Record<string, Omit<TProgress, 'activities'>>);
  }

  return acc;
}, {} as Record<string, TProgress>));

const sortedProgress = computed(() => Object.values(taxonomyProjectsProgress.value).sort((a, b) => {
  const percentAlignedA = (a.aligned / a.turnover) * 100;
  const percentAlignedB = (b.aligned / b.turnover) * 100;
  const percentEligibleA = (a.eligible / a.turnover) * 100;
  const percentEligibleB = (b.eligible / b.turnover) * 100;

  if (percentAlignedA > percentAlignedB) return -1;
  if (percentAlignedA < percentAlignedB) return 1;
  if (percentEligibleA > percentEligibleB) return -1;
  if (percentEligibleA < percentEligibleB) return 1;

  return 0;
}));

const groups = ['best', 'worst'] as const;
const items = computed(() => {
  const copiedArray = sortedProgress.value.slice();
  const middleIndex = Math.ceil(copiedArray.length / 2);

  return {
    best: copiedArray.splice(0, middleIndex),
    worst: copiedArray.splice(-middleIndex),
  };
});

</script>

<template>
  <div class="px-6 grid grid-cols-1 2xl:grid-cols-2 gap-10">
    <div v-if="!groups.length">
      <h3 class="font-semibold leading-5 text-gray-900 mb-6">
        {{ t('Most Taxonomy-aligned projects') }}
      </h3>
      <MlEmptyStateCard
        class="!items-start"
        :title="t('Find out which of your projects has the most business activities classified as sustainable')"
        :description="t('Create your first project to calculate your Taxonomy result. A project can be a business unit, site, asset, business entity or other.')"
        :buttonText="t('Create first project')"
        @buttonClick="router.push({ name: 'taxonomyProjects' })"
      />
    </div>
    <div v-if="!groups.length">
      <h3 class="font-semibold leading-5 text-gray-900 mb-6">
        {{ t('Least Taxonomy-aligned projects') }}
      </h3>
      <MlEmptyStateCard
        class="!items-start"
        :title="t('Find out which of your projects can be improved')"
        :description="t('Create your first project to calculate your Taxonomy result. A project can be a business unit, site, asset, business entity or other.')"
        :buttonText="t('Create first project')"
        @buttonClick="router.push({ name: 'taxonomyProjects' })"
      />
    </div>
    <div v-for="group in groups" :key="group">
      <h2 class="font-medium">
        <template v-if="group === 'best'">
          <h3 class="font-semibold leading-5 text-gray-900 mb-6">
            {{ t('Most Taxonomy-aligned projects') }}
          </h3>
        </template>
        <template v-else-if="group === 'worst'">
          <h3 class="font-semibold leading-5 text-gray-900 mb-6">
            {{ t('Least Taxonomy-aligned projects') }}
          </h3>
        </template>
      </h2>
      <div class="mt-2">
        <OgSimpleTable
          :fields="headers"
          :items="items[group]"
          isFixedLayout
        >
          <template #item-name="item">
            {{ item.name }}
          </template>
          <template #item-answeredProgress="item">
            <AtProgress
              percentDecimals
              showProgressionColors
              :percent="Number(item.eligible / item.turnover * 100) ? Number(item.eligible / item.turnover * 100) : 0"
              showPercentNumber
            />
          </template>
          <template #item-acceptedProgress="item">
            <AtProgress
              percentDecimals
              showProgressionColors
              :percent="Number(item.aligned / item.turnover * 100) ? Number(item.aligned / item.turnover * 100) : 0"
              showPercentNumber
            />
          </template>
        </OgSimpleTable>
      </div>
    </div>
  </div>
</template>
