import { notify } from '@kyvg/vue3-notification';
import { ApolloError } from '@apollo/client/core';
import { useI18n } from 'vue-i18n';
import useDeleteTaxonomyProjectMutation from '@/api/mutations/Taxonomy/deleteTaxonomyProject.mutation';
import useConfirmViaDialog, { type TDialogData } from '@/utils/composables/useConfirmViaDialog';

export const useDeleteProject = (...mutationOptions: Parameters<typeof useDeleteTaxonomyProjectMutation>) => {
  const { t } = useI18n();

  const { mutate, loading: deleteProjectLoading } = useDeleteTaxonomyProjectMutation(...mutationOptions);
  const { confirmViaDialog } = useConfirmViaDialog();

  const deleteProject = async (id: string, dialogOptions = {} as TDialogData) => {
    const isConfirmed = await confirmViaDialog({
      title: t('Are you sure you want to delete this project?'),
      text: t('This will automatically delete all subordinate activities.'),
      confirmLabel: t('Delete'),
      cancelLabel: t('Cancel'),
      confirmButtonVariant: 'destructive',
      ...dialogOptions,
    });

    if (!isConfirmed) return;

    try {
      return mutate({ _id: id }, { update: (store) => store.evict({ fieldName: 'getTaxonomyProjects' }) });
    } catch (e) {
      if (e instanceof ApolloError) {
        notify({
          type: 'error',
          text: t(e.message),
          duration: 10000,
        });
      }
    }
  };

  return { deleteProject, deleteProjectLoading };
};
