<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import isNil from 'lodash/isNil';
import { DotsHorizontalIcon } from '@heroicons/vue/outline';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import OgSimpleTable from '@/components/organisms/OgSimpleTable/OgSimpleTable.vue';
import { type PgTaxonomyAssessmentQuery, TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';

type TurnoverFormData = {
  totalTurnover: number | null;
  totalTurnoverCompleteLater: boolean;
  activityTurnovers: Record<string, number>;
};

const { t } = useI18n();

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment']
}

const router = useRouter();
const props = defineProps<TProps>();
const { formatNumber } = useFormatNumber();
const { confirmViaDialog } = useConfirmViaDialog();

const formData = reactive<TurnoverFormData>({
  totalTurnover: -1,
  totalTurnoverCompleteLater: false,
  activityTurnovers: {},
});

watch(() => props.assessment, () => {
  if (Object.keys(formData.activityTurnovers).length === 0) {
    formData.activityTurnovers = props.assessment.businessActivities
      .reduce((acc, curr) => ({ ...acc, [curr._id]: Math.max(0, curr.activityTurnover) }), {});
  }

  formData.totalTurnoverCompleteLater = props.assessment.turnoverCompleteLater;

  if (formData.totalTurnoverCompleteLater) {
    formData.totalTurnover = null;
  } else if (formData.totalTurnover === -1) {
    formData.totalTurnover = Math.max(0, props.assessment.turnover);
  }
}, { immediate: true });

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } = useUpdateTaxonomyAssessmentBusinessActivity();

watch(() => formData.totalTurnover, (newTotalTurnoverValue) => {
  if (typeof newTotalTurnoverValue === 'number') {
    formData.totalTurnoverCompleteLater = false;
  }
});

const taxonomyActivitiesTurnoverSum = computed(() => {
  return Object.values(formData.activityTurnovers).reduce((acc, curr) => acc + curr, 0);
});
const taxonomyActivitiesTurnoverPercent = computed(() => {
  if (formData.totalTurnoverCompleteLater || isNil(formData.totalTurnover)) {
    return null;
  }

  return (taxonomyActivitiesTurnoverSum.value / formData.totalTurnover) * 100;
});
const activitiesNotIncludedSum = computed(() => {
  if (formData.totalTurnoverCompleteLater || isNil(formData.totalTurnover)) {
    return null;
  }

  return formData.totalTurnover - taxonomyActivitiesTurnoverSum.value;
});
const activitiesNotIncludedPercent = computed(() => {
  if (formData.totalTurnoverCompleteLater || isNil(taxonomyActivitiesTurnoverPercent.value)) {
    return null;
  }

  return 100 - taxonomyActivitiesTurnoverPercent.value;
});

const fields = ['Activity', 'Turnover', 'Turnover Proportion'];
const items = computed(() => [
  ...props.assessment.businessActivities.map((activity) => ({
    '_id': activity._id,
    'Activity': `${activity.activity.number}: ${t(activity.activity.name)}`,
    'Turnover': activity.activityTurnover,
    'Turnover Proportion': !isNil(formData.totalTurnover) && !formData.totalTurnoverCompleteLater ? ((formData.activityTurnovers[activity._id] ?? 0) / Math.max(1, formData.totalTurnover)) * 100 : null,
  })),
  {
    'Activity': t('Activities eligible under the EU Taxonomy'),
    'Turnover': taxonomyActivitiesTurnoverSum.value,
    'Turnover Proportion': taxonomyActivitiesTurnoverPercent.value,
  },
  {
    'Activity': t('Activities not eligible under the EU Taxonomy'),
    'Turnover': activitiesNotIncludedSum.value,
    'Turnover Proportion': activitiesNotIncludedPercent.value,
  },
  {
    'Activity': t('TOTAL'),
    'Turnover': !isNil(activitiesNotIncludedSum.value) ? (taxonomyActivitiesTurnoverSum.value + activitiesNotIncludedSum.value) : null,
    'Turnover Proportion': !isNil(activitiesNotIncludedPercent.value) && !isNil(taxonomyActivitiesTurnoverPercent.value) ? (activitiesNotIncludedPercent.value + taxonomyActivitiesTurnoverPercent.value) : null,
  },
]);

const canGoNext = computed(() => {
  if (formData.totalTurnoverCompleteLater) {
    return true;
  }

  if (isNil(formData.totalTurnover)) {
    return false;
  }

  if (!isNil(taxonomyActivitiesTurnoverPercent.value)) {
    return taxonomyActivitiesTurnoverPercent.value <= 100;
  }

  return true;
});

const isLoading = ref(false);

const next = async () => {
  isLoading.value = true;

  await updateTaxonomyAssessment({
    assessmentId: props.assessment._id,
    input: {
      turnover: !isNil(formData.totalTurnover) && formData.totalTurnover >= 0 ? formData.totalTurnover : -1,
      turnoverCompleteLater: formData.totalTurnoverCompleteLater,
      status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_CAPEX,
    },
  }, {
    update: (store) => {
      store.evict({ fieldName: 'getTaxonomyProjects' });
      store.evict({ fieldName: 'getTaxonomyAssessment' });
    },
  });

  for await (const [key, value] of Object.entries(formData.activityTurnovers)) {
    await updateTaxonomyAssessmentBusinessActivity({
      assessmentId: props.assessment._id,
      businessActivityId: key,
      input: {
        activityTurnover: typeof value === 'number' && value >= 0 ? value : -1,
      },
    }, {
      update: (store) => {
        store.evict({ fieldName: 'getTaxonomyProjects' });
        store.evict({ fieldName: 'getTaxonomyAssessment' });
      },
    });
  }

  router.push({ name: 'taxonomyAssessmentBusinessActivitiesCapex' });

  isLoading.value = false;
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentBusinessActivitiesSectorActivities' });
};

async function handleCompleteLaterClick() {
  const isConfirmed = await confirmViaDialog({
    title: t('Complete this input later'),
    text: `<p>${t('Marking this field means you want to add your financial data later, while going through other stages of your EU Taxonomy assessment. You will be reminded to add the data and you will not be able to complete the assessments without it.')}</p><br /><p>${t('Would you like to continue?')}</p>`,
    renderHTML: true,
    confirmLabel: t('Confirm'),
    cancelLabel: t('Go back'),
  });

  if (isConfirmed) {
    formData.totalTurnover = null;
    formData.totalTurnoverCompleteLater = true;
  }
}
</script>

<template>
  <div>
    <AtInput
      v-model.number="formData.totalTurnover"
      type="number"
      :placeholder="t(formData.totalTurnoverCompleteLater ? 'Saved for later - complete now by typing in this field' : 'EUR')"
      unit="EUR"
    >
      <template #label="{ id }">
        <div class="flex items-center space-x-4 mb-1">
          <label :for="id" class="block text-sm font-medium text-gray-700">
            {{ t('What is the total amount of company’s turnover?') }}
          </label>
          <MlMenu>
            <button type="button" class="group flex items-center">
              <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50" />
            </button>
            <template #menuItems>
              <AtMenuItem class="justify-center" @click="handleCompleteLaterClick">
                {{ t('Complete later') }}
              </AtMenuItem>
            </template>
          </MlMenu>
        </div>
      </template>
    </AtInput>

    <p class="my-4">
      {{ t('State your turnover from each of the selected activities') }}
    </p>

    <OgSimpleTable
      :fields="fields"
      :items="items"
    >
      <template #item-activity="item">
        {{ t(item.Activity) }}
      </template>
      <template #item-turnover="item">
        <AtInput v-if="item._id" v-model.number="formData.activityTurnovers[item._id]" unit="EUR" type="number" />
        <template v-else>
          {{ formatNumber(item.Turnover) }} EUR
        </template>
      </template>
      <template #item-turnoverProportion="item">
        <template v-if="item['Turnover Proportion'] >= 0">
          {{ formatNumber(item['Turnover Proportion']) }}%
        </template>
      </template>
    </OgSimpleTable>

    <p class="font-semibold">
      {{ t('Your total Taxonomy-eligible turnover is {number} Euro.', { number: formatNumber(taxonomyActivitiesTurnoverSum) }) }}
    </p>
  </div>

  <div class="flex justify-between">
    <AtButton class="my-4 float-right" :disabled="isLoading" variant="outline" @click="prev">
      {{ t('Back') }}
    </AtButton>

    <AtButton
      class="my-4 float-right"
      :loading="isLoading"
      :disabled="!canGoNext"
      @click="next"
    >
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
