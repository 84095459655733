import { gql } from '@apollo/client/core';

export default gql`
  query PgNotificationSettings {
    getCurrentEntitySettings {
      _id
      companyName
      notificationSettings {
        _id
        rejectedDatapoints
        approvedDatapoints
        openDatapoints
        openApprovals
        goals
        newProject
        recommendationsVisibility
        voluntaryVisibility
      }
    }
  }
`;
