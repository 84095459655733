<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import isNil from 'lodash/isNil';
import { DotsHorizontalIcon } from '@heroicons/vue/outline';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import OgSimpleTable from '@/components/organisms/OgSimpleTable/OgSimpleTable.vue';
import { TaxonomyAssessmentStatusEnum, type PgTaxonomyAssessmentQuery } from '@/__generated__/types';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useUpdateTaxonomyAssessmentBusinessActivity
  from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import MlRadio from '@/components/molecules/MlRadio.vue';

type OpexFormData = {
  totalOpex: number | null;
  totalOpexCompleteLater: boolean;
  activityOpexs: Record<string, number>;
};

const { t } = useI18n();
const router = useRouter();
const { formatNumber } = useFormatNumber();
const { confirmViaDialog } = useConfirmViaDialog();

const props = defineProps<Props>();
type Props = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment']
}

const formData = reactive<OpexFormData>({
  totalOpex: -1,
  totalOpexCompleteLater: false,
  activityOpexs: {},
});

const hasOpex = ref(false);

watch(() => props.assessment, () => {
  if (Object.keys(formData.activityOpexs).length === 0) {
    formData.activityOpexs = props.assessment.businessActivities
      .reduce((acc, curr) => ({ ...acc, [curr._id]: Math.max(0, curr.activityOpex) }), {});
  }

  formData.totalOpexCompleteLater = props.assessment.opexCompleteLater;

  if (formData.totalOpexCompleteLater) {
    formData.totalOpex = null;
  } else if (formData.totalOpex === -1) {
    formData.totalOpex = Math.max(0, props.assessment.opex);
  }

  if (props.assessment.opex >= 0 || props.assessment.opexCompleteLater) {
    hasOpex.value = true;
  }
}, { immediate: true });

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } = useUpdateTaxonomyAssessmentBusinessActivity();

watch(() => formData.totalOpex, (newTotalOpexValue) => {
  if (typeof newTotalOpexValue === 'number') {
    formData.totalOpexCompleteLater = false;
  }
});

const taxonomyActivitiesOpexSum = computed(() => {
  return Object.values(formData.activityOpexs).reduce((acc, curr) => acc + curr, 0);
});
const taxonomyActivitiesOpexPercent = computed(() => {
  if (formData.totalOpexCompleteLater || isNil(formData.totalOpex)) {
    return null;
  }

  return (taxonomyActivitiesOpexSum.value / formData.totalOpex) * 100;
});
const activitiesNotIncludedSum = computed(() => {
  if (formData.totalOpexCompleteLater || isNil(formData.totalOpex)) {
    return null;
  }

  return formData.totalOpex - taxonomyActivitiesOpexSum.value;
});
const activitiesNotIncludedPercent = computed(() => {
  if (formData.totalOpexCompleteLater || isNil(taxonomyActivitiesOpexPercent.value)) {
    return null;
  }

  return 100 - taxonomyActivitiesOpexPercent.value;
});

const radioOptions = [
  { name: 'yes', label: 'Yes', value: true },
  { name: 'no', label: 'No', value: false },
];

const fields = ['Activity', 'Opex', 'Opex Proportion'];
const items = computed(() => [
  ...props.assessment.businessActivities.map((activity) => ({
    '_id': activity._id,
    'Activity': `${activity.activity.number}: ${t(activity.activity.name)}`,
    'Opex': activity.activityOpex,
    'Opex Proportion': !isNil(formData.totalOpex) && !formData.totalOpexCompleteLater ? ((formData.activityOpexs[activity._id] ?? 0) / Math.max(1, formData.totalOpex)) * 100 : null,
  })),
  {
    'Activity': t('Activities eligible under the EU Taxonomy'),
    'Opex': taxonomyActivitiesOpexSum.value,
    'Opex Proportion': taxonomyActivitiesOpexPercent.value,
  },
  {
    'Activity': t('Activities not eligible under the EU Taxonomy'),
    'Opex': activitiesNotIncludedSum.value,
    'Opex Proportion': activitiesNotIncludedPercent.value,
  },
  {
    'Activity': t('TOTAL'),
    'Opex': !isNil(activitiesNotIncludedSum.value) ? (taxonomyActivitiesOpexSum.value + activitiesNotIncludedSum.value) : null,
    'Opex Proportion': !isNil(activitiesNotIncludedPercent.value) && !isNil(taxonomyActivitiesOpexPercent.value) ? (activitiesNotIncludedPercent.value + taxonomyActivitiesOpexPercent.value) : null,
  },
]);

const isLoading = ref(false);

const canGoNext = computed(() => {
  if (!hasOpex.value) {
    return true;
  }

  if (formData.totalOpexCompleteLater) {
    return true;
  }

  if (isNil(formData.totalOpex)) {
    return false;
  }

  if (!isNil(taxonomyActivitiesOpexPercent.value)) {
    return taxonomyActivitiesOpexPercent.value <= 100;
  }

  return true;
});

const next = async () => {
  isLoading.value = true;

  for await (const [key, value] of Object.entries(formData.activityOpexs)) {
    await updateTaxonomyAssessmentBusinessActivity({
      assessmentId: props.assessment._id,
      businessActivityId: key,
      input: {
        activityOpex: hasOpex.value && typeof value === 'number' && value >= 0 ? value : -1,
      },
    }, {
      update: (store) => {
        store.evict({ fieldName: 'getTaxonomyProjects' });
        store.evict({ fieldName: 'getTaxonomyAssessment' });
      },
    });
  }

  const result = await updateTaxonomyAssessment({
    assessmentId: props.assessment._id,
    input: {
      opex: hasOpex.value && !isNil(formData.totalOpex) && formData.totalOpex >= 0 ? formData.totalOpex : -1,
      opexCompleteLater: formData.totalOpexCompleteLater,
      status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_SUBSTANTIAL_CONTRIBUTION,
    },
  }, {
    update: (store) => {
      store.evict({ fieldName: 'getTaxonomyProjects' });
      store.evict({ fieldName: 'getTaxonomyAssessment' });
    },
  });

  const isAssessmentCompleted = !result?.data?.updateTaxonomyAssessment.turnoverCompleteLater
    && (result?.data?.updateTaxonomyAssessment.turnover === 0
      || result?.data?.updateTaxonomyAssessment.businessActivities.every((activity) => activity.activityTurnover === 0));

  if (isAssessmentCompleted) {
    await updateTaxonomyAssessment({
      assessmentId: props.assessment._id,
      input: {
        status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
      },
    }, {
      update: (store) => {
        store.evict({ fieldName: 'getTaxonomyProjects' });
        store.evict({ fieldName: 'getTaxonomyAssessment' });
      },
    });
    router.push({ name: 'taxonomyAssessmentDocuments' });
  } else {
    router.push({ name: 'taxonomyAssessmentSubstantialContributionObjectives' });
  }

  isLoading.value = false;
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentBusinessActivitiesCapex' });
};

async function handleCompleteLaterClick() {
  const isConfirmed = await confirmViaDialog({
    title: t('Complete this input later'),
    text: `<p>${t('Marking this field means you want to add your financial data later, while going through other stages of your EU Taxonomy assessment. You will be reminded to add the data and you will not be able to complete the assessments without it.')}</p><br /><p>${t('Would you like to continue?')}</p>`,
    renderHTML: true,
    confirmLabel: t('Confirm'),
    cancelLabel: t('Go back'),
  });

  if (isConfirmed) {
    formData.totalOpex = null;
    formData.totalOpexCompleteLater = true;
  }
}
</script>

<template>
  <div class="mb-4">
    <p class="mb-2">
      {{ t('Do you want to assign OpEx to your activities?') }}
    </p>
    <MlRadio
      v-model="hasOpex"
      class="ml-6"
      :options="radioOptions"
    />
  </div>
  <div v-if="hasOpex">
    <AtInput
      v-model.number="formData.totalOpex"
      type="number"
      :placeholder="t(formData.totalOpexCompleteLater ? 'Saved for later - complete now by typing in this field' : 'EUR')"
      unit="EUR"
    >
      <template #label="{ id }">
        <div class="flex items-center space-x-4 mb-1">
          <label :for="id" class="block text-sm font-medium text-gray-700">
            {{ t('What is the total amount of company’s opex?') }}
          </label>
          <MlMenu>
            <button type="button" class="group flex items-center">
              <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50" />
            </button>
            <template #menuItems>
              <AtMenuItem class="justify-center" @click="handleCompleteLaterClick">
                {{ t('Complete later') }}
              </AtMenuItem>
            </template>
          </MlMenu>
        </div>
      </template>
    </AtInput>

    <p class="my-4">
      {{ t('State your opex from each of the selected activities') }}
    </p>

    <OgSimpleTable
      :fields="fields"
      :items="items"
    >
      <template #item-activity="item">
        {{ t(item.Activity) }}
      </template>
      <template #item-opex="item">
        <AtInput v-if="item._id" v-model.number="formData.activityOpexs[item._id]" unit="EUR" type="number" />
        <template v-else>
          {{ formatNumber(item.Opex) }} EUR
        </template>
      </template>
      <template #item-opexProportion="item">
        <template v-if="item['Opex Proportion'] >= 0">
          {{ formatNumber(item['Opex Proportion']) }}%
        </template>
      </template>
    </OgSimpleTable>

    <p class="font-semibold">
      {{ t('Your total Taxonomy-eligible opex is {number} Euro.', { number: formatNumber(taxonomyActivitiesOpexSum) }) }}
    </p>
  </div>

  <div class="flex justify-between">
    <AtButton class="my-4 float-right" :disabled="isLoading" variant="outline" @click="prev">
      {{ t('Back') }}
    </AtButton>

    <AtButton
      class="my-4 float-right"
      :loading="isLoading"
      :disabled="!canGoNext"
      @click="next"
    >
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
