import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable';
import {
  type DataPointRequestXlsxDownloadQuery,
  type DataPointRequestXlsxDownloadQueryVariables,
} from '@/__generated__/types';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import { apolloClient } from '@/api/apollo/client';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import generateReport from '@/utils/helpers/generateReport';
import DATAPOINTREQUEST_XLSX_DOWNLOAD from './dataPointRequestXlsxDownload.query';

export function useDataPointRequestXlsxDownload() {
  const { currentUser } = useCurrentUser();
  const query = provideApolloClient(apolloClient)(
    () => useLazyQuery<DataPointRequestXlsxDownloadQuery, DataPointRequestXlsxDownloadQueryVariables>(
      DATAPOINTREQUEST_XLSX_DOWNLOAD,
      undefined,
      { fetchPolicy: 'network-only' },
    ));

  const getFileData = async (dataPointRequestIds: string[]) => {
    const requestData: DataPointRequestXlsxDownloadQueryVariables = {
      dataPointRequestIds,
    };
    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);
    if (result && !(result instanceof Error)) {
      return result?.getDataPointRequestsEncodedReport;
    }
  };

  const exportFile = async (dataPointRequestIds: string[]) => {
    const fileData = await getFileData(dataPointRequestIds);
    if (fileData) {
      generateReport(fileData, currentUser.value?.lastName, 'xlsx');
    }
  };

  return {
    loading: query.loading,
    exportFile,
    getFileData,
  };
}
