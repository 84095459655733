<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ViewListIcon from '@heroicons/vue/outline/ViewListIcon';
import { STAKEHOLDER_ENGAGE_SURVEY_NAME } from '../../utils';
import { useStore } from '../../store';
import AtEmptyCard from '../../AtEmptyCard.vue';
import OgSurveys from './OgSurveys.vue';

const { t } = useI18n();
const store = useStore();

const hasSurveys = computed(() => store.value.stakeholders.some((stakeholder) => stakeholder.engage === STAKEHOLDER_ENGAGE_SURVEY_NAME));
</script>

<template>
  <AtEmptyCard
    v-if="!hasSurveys"
    variant="center"
    :icon="ViewListIcon"
    :title="t('No stakeholders yet')"
  >
    <p>
      {{ t('Once you decide on which of your stakeholders will be engaged by the stakeholder surveys, you can send your surveys via this page.') }}
    </p>
  </AtEmptyCard>

  <OgSurveys v-else />
</template>
