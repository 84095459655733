import { createApp, h } from 'vue';
import notifications from '@kyvg/vue3-notification';
import FloatingVue from 'floating-vue';
import App from '@/App.vue';
import { registerDirectives } from '@/utils/directives';
import { registerApollo } from '@/api/apollo';
import { i18n } from '@/lib/i18n';
import { registerSentry } from '@/lib/sentry';
import { router } from '@/router';
import '@/lib/dayjs/config';
import '@/index.css';
import { initFlagsmith } from '@/lib/flagsmith';

const app = createApp({
  render: () => h(App),
});

registerApollo(app);
registerDirectives(app);

app.use(router);
app.use(i18n);
app.use(notifications);
app.use(FloatingVue);

initFlagsmith();

registerSentry(app, router);

router.beforeEach((to, from, next) => {
  const title = 'CodioImpact';
  const env = import.meta.env.VITE_APP_ENV;
  const isProdEnv = env === 'production';

  document.title = isProdEnv
    ? title
    : `${env} - ${title}`;

  next();
});

(async () => {
  app.mount('#app');
})();
