<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DoubleMaterialitySurveyType } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { useStore } from '../../store';
import OgTable from '../../OgTable.vue';

type RowItem = {
  _id: string;
  index: number;
  stakeholder: string;
  fullName: string;
};

export type SaveEvent = Record<string, DoubleMaterialitySurveyType>;

const emit = defineEmits<{
  close: [];
  save: [event: SaveEvent];
}>();

const { t } = useI18n();
const store = useStore();

const impactSurveysPercentageAsString = ref('');

const typesForm = reactive<Record<string, DoubleMaterialitySurveyType | null>>({});

const surveys = computed<RowItem[]>(() => {
  const stakeholders = store.value.stakeholders.reduce<Record<string, string>>((acc, stakeholder) => {
    acc[stakeholder._id] = stakeholder.stakeholder ?? '';
    return acc;
  }, {});

  return store.value.surveys.map((survey, index) => {
    return {
      _id: survey._id,
      index,
      fullName: survey.fullName,
      stakeholder: survey.stakeholderId ? (stakeholders[survey.stakeholderId] ?? '') : '',
    };
  });
});

const headers = computed(() => [
  { text: t('Stakeholder'), value: 'stakeholder' },
  { text: t('Full name'), value: 'fullName' },
  { text: t('Type assigned'), value: 'type' },
]);

const percentageOptions = computed(() => {
  const result: Record<string, string> = {};

  for (let i = 5; i <= 100; i += 5) {
    result[i.toString()] = `${i.toString()}%`;
  }

  return result;
});
const risksAndOpportunitiesPercentage = computed(() => {
  if (impactSurveysPercentageAsString.value) {
    return `${100 - parseInt(impactSurveysPercentageAsString.value, 10)}%`;
  }

  return '100%';
});

const typeOptions = computed(() => ({
  [DoubleMaterialitySurveyType.IMPACT]: t('Impact'),
  [DoubleMaterialitySurveyType.RISKS_OPPORTUNITIES]: t('Risks/opportunities'),
}));

const isValid = computed(() => surveys.value.every((survey) => !!typesForm[survey._id]));

watch(
  [
    impactSurveysPercentageAsString,
    surveys,
  ],
  ([newImpactSurveysPercentageAsString, newSurveys]) => {
    if (newImpactSurveysPercentageAsString) {
      const value = parseInt(newImpactSurveysPercentageAsString, 10) / 100;

      Object.keys(typesForm).forEach((key) => {
        delete typesForm[key];
      });

      newSurveys.forEach((survey) => {
        if (Math.random() <= value) {
          typesForm[survey._id] = DoubleMaterialitySurveyType.IMPACT;
        } else {
          typesForm[survey._id] = DoubleMaterialitySurveyType.RISKS_OPPORTUNITIES;
        }
      });
    }
  },
);

function handleClose() {
  emit('close');
}

function handleSubmit() {
  if (isValid.value) {
    const event: SaveEvent = {};
    Object.keys(typesForm).forEach((key) => {
      if (typesForm[key]) {
        event[key] = typesForm[key];
      }
    });

    emit('save', event);
  }
}
</script>

<template>
  <MlDialog
    isOpen
    :closeOnOutside="false"
    @close="handleClose"
  >
    <template #title>
      {{ t('Randomize assignation of surveys') }}
    </template>

    <template #default="{ close }">
      <form class="pt-9" @submit.prevent="handleSubmit">
        <hr class="mb-8" />
        <p class="text-xs mb-7">
          {{ t('To assist with assignment, you can choose the percentage of impact and risk/opportunity surveys to send to stakeholders. We recommend sending a higher proportion of impact surveys, ideally around 60-75% of the total.') }}
        </p>
        <div class="flex items-center space-x-10 mb-14">
          <div class="flex items-center space-x-4">
            <p class="font-medium">
              {{ t('Impact surveys:') }}
            </p>
            <MlSelect
              v-model="impactSurveysPercentageAsString"
              usePortal
              wrapperClass="w-24"
              :options="percentageOptions"
              :placeholder="t('Select')"
            />
          </div>
          <div class="flex items-center space-x-4">
            <p class="font-medium">
              {{ t('Risks and opportunities surveys:') }}
            </p>
            <AtInput wrapperClass="w-20" disabled :modelValue="risksAndOpportunitiesPercentage" />
          </div>
        </div>
        <OgTable
          :headers="headers"
          :items="surveys"
          :isRowRemovable="false"
        >
          <template #item-stakeholder="row: RowItem">
            <AtInput
              :modelValue="row.stakeholder"
              readonly
            />
          </template>
          <template #item-fullName="row: RowItem">
            <AtInput
              :modelValue="row.fullName"
              readonly
            />
          </template>
          <template #item-type="row: RowItem">
            <MlSelect
              :modelValue="typesForm[row._id] ?? undefined"
              usePortal
              :options="typeOptions"
              :placeholder="t('Select')"
              @update:modelValue="typesForm[row._id] = $event ?? null"
            />
          </template>
        </OgTable>
        <div class="flex space-x-2 justify-between">
          <AtButton type="button" variant="outline" @click="close">
            {{ t('Close') }}
          </AtButton>
          <AtButton type="submit" :disabled="!isValid">
            {{ t('Save') }}
          </AtButton>
        </div>
      </form>
    </template>
  </MlDialog>
</template>
